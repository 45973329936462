.play-popover {
    --offset-y: -120px;
    .popover-content {
        top: -100% !important;
    }
}

.bottom-nav {
    ion-button {
        &.selected {
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: -0.5rem;
                left: 0;
                width: 100%;
                height: 3px;
                background-color: var(--gladiator-gold-dark);
            }
        }
    }
}