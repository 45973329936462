.ability-icon {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0.4rem;
    svg {
        width: 100%;
        height: 100%;
        object-fit: contain;
        path, polygon {
            fill: #fff;
        }
    }
}