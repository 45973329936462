$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/cosmicmuffins/fx';

// Full Screen Abilities

#center-stage {

    .fx {

        &.butter-blast {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: butterBlastGlow 2.5s infinite;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="butter-blast-attack"] {
            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashButter 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: butterBlastGlow 2s linear infinite;
                        }
                    }
                    .aspect-ratio--object {
                        .fx {
                            &.butter-blast-attack {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(0deg);
                                width: 110%;
                                height: 120%;
                                background-image: url('#{$spacesStorage}/butter-blast/opponent/impact.png');
                                background-size: 100% 100%;
                                animation: butterBlastGlow 2.5s infinite;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                top: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
            &.opponent {
                .aspect-ratio--object {
                    animation: flashButter 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .flip-card-inner {
                        animation: butterBlastGlow 2s linear infinite;
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        .fx {
                            &.butter-blast-attack {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(180deg);
                                width: 110%;
                                height: 120%;
                                background-image: url('#{$spacesStorage}/butter-blast/opponent/impact.png');
                                background-size: 100% 100%;
                                animation: butterBlastGlow 2.5s infinite;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                bottom: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashButter {
    0% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem yellow);
    }

    50% {
        filter: brightness(300%) drop-shadow(0px 0px 1rem white);
    }

    100% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem yellow);
    }
}

@keyframes butterBlastGlow {
    0% {
        filter: drop-shadow(0px 0px 0.25rem yellow) sepia(1) hue-rotate(1deg) saturate(5);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem yellow) sepia(1) hue-rotate(1deg) saturate(5);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem yellow) sepia(1) hue-rotate(1deg) saturate(5);
    }
}