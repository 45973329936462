.avatar-upload {
    ion-avatar {
        cursor: pointer;
        min-width: 80px;
        min-height: 80px;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        overflow: hidden;
    }
    &.small {
        ion-avatar {
            width: 30px;
            height: 30px;
        }    
    }
    &.large {
        ion-avatar {
            max-width: 123px;
            max-height: 123px;
        }   
    }
}

#wallet-settings {
    .funds-detail {
        & > p {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .currency-amount {
            margin-bottom: 0.25rem;
        }
        .currency-label {
            font-weight: 600;
            font-size: 0.75rem;
            margin-bottom: 0.75rem;
        }
    }
}