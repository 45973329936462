.release-notes-modal {
    --background-color: transparent;
    ion-header {
        --background-color: transparent;
        --background: transparent;
    }
    ion-toolbar {
        --background: transparent;
    }
    ion-content {
        --background: transparent;
    }
    ion-footer {
        --background-color: transparent;
        --background: transparent;
    }
}
