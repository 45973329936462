@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes circle-size {
    from {
        width: 250px;
        height: 250px;
    }

    to {
        width: 300px;
        height: 300px;
    }
}


@keyframes shadow-rotate {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@keyframes shadow-rotate2 {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(-360deg);
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {

    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #976b38;
    }
}







@keyframes gradientUp {
    0% {
        background-position: 100% 0%;
    }

    50% {
        background-position: 0% 200%;
    }

    100% {
        background-position: 200% 400%;
    }
}

@keyframes gradientDown {
    0% {
        background-position: 200% 400%;
    }

    50% {
        background-position: 0% 200%;
    }

    100% {
        background-position: 100% 0%;
    }
}


@keyframes cursorEffect {
    0% {
        filter: hue-rotate(0deg) drop-shadow(0px 0px 0.25rem var(--space-vaporwave-blue));
    }

    50% {
        filter: hue-rotate(180deg) drop-shadow(0px 0px 1rem var(--space-vaporwave-pink));
    }

    100% {
        filter: hue-rotate(360deg) drop-shadow(0px 0px 0.25rem var(--space-vaporwave-blue));
    }
}






@keyframes hueRotate {
    0% {
        filter: hue-rotate(0deg);
    }

    50% {
        filter: hue-rotate(180deg);
    }

    100% {
        filter: hue-rotate(360deg);
    }
}

@keyframes glowSelected {
    $color1: #f729ff;
    $color2: #009dff;

    0% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem $color1);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }
}

@keyframes glowSelectedGreen {
    $color1: #00ff0d;
    $color2: #99ff00;

    0% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem $color1);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }
}

@keyframes glowSelectedGold {
    $color1: var(--gladiator-gold);
    $color2: var(--gladiator-gold-dark);

    0% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem $color1);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }
}

@keyframes glowSelectedBright {
    $color1: #fff;
    $color2: #fff;

    0% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem $color1);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem $color2);
    }
}

@keyframes resurrected {
    $color1: black;
    $color2: white;

    0% {
        filter: drop-shadow(0px 0px 0.25rem $color2) saturate(0) contrast(1.5);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem $color1) saturate(0) contrast(1);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem $color2) saturate(0) contrast(1.5);
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

$shake-turbulence: 6px;

@keyframes shakeTurbulent {
    0% {
        transform: translate(1 * $shake-turbulence, 1 * $shake-turbulence) rotate(0deg);
    }

    10% {
        transform: translate(-1 * $shake-turbulence, -2 * $shake-turbulence) rotate(-1deg);
    }

    20% {
        transform: translate(-3 * $shake-turbulence, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3 * $shake-turbulence, 2 * $shake-turbulence) rotate(0deg);
    }

    40% {
        transform: translate(1 * $shake-turbulence, -1 * $shake-turbulence) rotate(1deg);
    }

    50% {
        transform: translate(-1 * $shake-turbulence, 2 * $shake-turbulence) rotate(-1deg);
    }

    60% {
        transform: translate(-3 * $shake-turbulence, 1 * $shake-turbulence) rotate(0deg);
    }

    70% {
        transform: translate(3 * $shake-turbulence, 1 * $shake-turbulence) rotate(-1deg);
    }

    80% {
        transform: translate(-1 * $shake-turbulence, -1 * $shake-turbulence) rotate(1deg);
    }

    90% {
        transform: translate(1 * $shake-turbulence, 2 * $shake-turbulence) rotate(0deg);
    }

    100% {
        transform: translate(1 * $shake-turbulence, -2 * $shake-turbulence) rotate(-1deg);
    }
}

@keyframes pulseLight {
    0% {
        filter: brightness(1);
    }
    50% {
        filter: brightness(0.7);
    }
    100% {
        filter: brightness(1);
    }
}