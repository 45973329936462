.ion-page-lobby {

    ion-content {
        --background: transparent;
        --overflow: hidden;
    }

    .scrollable-page-content {
        align-items: flex-start;
    }
    
    .colyseus-room {
        border: 2px solid var(--gladiator-gold-dark);
        background-color: rgb(39 31 21 / 0%);
        backdrop-filter: blur(1rem);
        max-width: 320px;    
        &.placeholder-row {
            min-height: 66px;
            // background-color: #1d170e;
        }
        &.created {
            // background-color: #5a492b;
        }
    }
    
}

