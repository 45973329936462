$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx';

// Full Screen Abilities

#center-stage {

    .fx {

        &.corruption-wave {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: corruptionWaveGlow 0.25s infinite;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="corruption-wave"] {
            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashCorruptionWave 0.5s infinite forwards, shakeTurbulent 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: flashCorruptionWave 1s linear infinite forwards;
                        }
                    }
                }
            }
            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashCorruptionWave 0.5s infinite forwards, shakeTurbulent 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: flashCorruptionWave 1s linear infinite forwards;
                        }
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashCorruptionWave {
    0% {
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 0, 242)) hue-rotate(0deg) saturate(2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem rgb(0, 128, 255)) hue-rotate(180deg) saturate(2);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem rgb(225, 0, 255)) hue-rotate(360deg) saturate(2);
    }
}

@keyframes corruptionWaveGlow {
    0% {
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 0, 242)) hue-rotate(0deg) saturate(2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem rgb(0, 128, 255)) hue-rotate(180deg) saturate(2);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem rgb(225, 0, 255)) hue-rotate(360deg) saturate(2);
    }
}
