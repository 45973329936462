$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bufficorns/fx';

// Full Screen Abilities

#center-stage {

    .fx {

        &.merge-conflict {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: mergeGlow 2.5s infinite;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="merge-conflict"] {

            .fx {
                &.merge-conflict {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
            }

            &.player {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashMerge 2s ease-out forwards;
                        .fx {
                            &.merge-conflict {
                                transform: translate3d(-50%, -75%, 0) scale(2.5) rotate(0deg);
                                width: 100%;
                                height: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                    .flip-card-inner {
                        opacity: 0;
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: mergeGlow 2s linear infinite;
                        }
                    }
                    .aspect-ratio--object {
                        .fx {
                            &.merge-conflict {
                                filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 300%;
                                height: 300%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
            &.opponent {
                .aspect-ratio--object {
                    animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .flip-card-inner {
                        animation: mergeGlow 2s linear infinite;
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        .fx {
                            &.merge-conflict {
                                filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 300%;
                                height: 300%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashMerge 2s ease-out forwards;
                        .fx {
                            &.merge-conflict {
                                transform: translate3d(-50%, -40%, 0) scale(2) rotate(0deg);
                                width: 100%;
                                height: 100%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                    .flip-card-inner {
                        opacity: 0;
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashMerge {
    0% {
        -webkit-filter: brightness(300%) drop-shadow(0px 0px 0.25rem rgb(246, 255, 0));
        filter: brightness(300%) drop-shadow(0px 0px 0.25rem rgb(246, 255, 0));
    }

    50% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 1rem rgb(255, 255, 255));
        filter: brightness(100%) drop-shadow(0px 0px 1rem rgb(255, 255, 255));
    }

    100% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(255, 255, 0));
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(255, 255, 0));
    }
}

@keyframes mergeGlow {
    0% {
        -webkit-filter: drop-shadow(0px 0px 0.25rem rgb(255, 255, 255)) sepia(1) hue-rotate(180deg) saturate(2.15);
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 255, 255)) sepia(1) hue-rotate(180deg) saturate(2.15);
    }

    50% {
        -webkit-filter: drop-shadow(0px 0px 3rem rgb(255, 255, 255)) sepia(1) hue-rotate(380deg) saturate(2.15);
        filter: drop-shadow(0px 0px 3rem rgb(255, 255, 255)) sepia(1) hue-rotate(380deg) saturate(2.15);
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 0.25rem rgb(255, 255, 255)) sepia(1) hue-rotate(180deg) saturate(2.15);
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 255, 255)) sepia(1) hue-rotate(180deg) saturate(2.15);
    }
}