$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/cosmicmuffins/fx';

// Full Screen Abilities

#center-stage {

    .fx {

        &.electronaut {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: electronautGlow 2.5s infinite;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                &.astronaut {
                    object-fit: contain;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0);
                    width: 420px;
                    height: 420px;
                    position: absolute;
                    z-index: 999999;
                    opacity: 0;
                    animation: fadeIn 500ms forwards;
                }
            }
            g {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="electronaut-attack"] {
            .aspect-ratio--object {
                animation: flashElectronaut 0.5s linear, shake 1.25s cubic-bezier(.36, .07, .19, .97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;
                .flip-card-inner {
                    animation: electronautGlow 2s linear infinite;
                }
            }
        }
        
    }
}

// Animations 

@keyframes flashElectronaut {
    0% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem #009dff);
    }

    50% {
        filter: brightness(300%) drop-shadow(0px 0px 1rem white);
    }

    100% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem #009dff);
    }
}

@keyframes electronautGlow {
    0% {
        filter: drop-shadow(0px 0px 0.25rem #009dff) sepia(1) hue-rotate(150deg) saturate(5);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem #009dff) sepia(1) hue-rotate(150deg) saturate(5);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem #009dff) sepia(1) hue-rotate(150deg) saturate(5);
    }
}
