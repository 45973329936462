$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx';

// Full Screen FX

#center-stage {

    .fx {

        &.hat-trick {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: hatTrickGlow 0.25s infinite;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                &.bun {
                    object-fit: contain;
                    top: 50%;
                    left: 50%;
                    transform: translate3d(-50%, -50%, 0) rotate(0deg);
                    transform-origin: center;
                    width: 250px;
                    height: 250px;
                    position: absolute;
                    z-index: 999999;
                    opacity: 0;
                    animation: fadeIn 500ms forwards;
                    border-radius: 50%;
                }
            }
            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
                img {
                    &.bun {
                        transform: translate3d(-50%, -50%, 0) rotate(180deg);
                    }
                }
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="hat-trick"] {
            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashHatTrick 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: flashHatTrick 1s linear infinite;
                        }
                    }
                    .aspect-ratio--object {
                        .fx {
                            &.hat-trick {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(0deg);
                                width: 110%;
                                height: 120%;
                                background-size: 100% 100%;
                                animation: flashHatTrick 1s infinite;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                top: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
            &.opponent {
                .aspect-ratio--object {
                    animation: flashHatTrick 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .flip-card-inner {
                        animation: flashHatTrick 1s linear infinite;
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        .fx {
                            &.hat-trick {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(180deg);
                                width: 110%;
                                height: 120%;
                                background-size: 100% 100%;
                                animation: flashHatTrick 1s infinite;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                bottom: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashHatTrick {
    0% {
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 0, 242)) hue-rotate(0deg) saturate(2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem rgb(0, 128, 255)) hue-rotate(180deg) saturate(2);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem rgb(225, 0, 255)) hue-rotate(360deg) saturate(2);
    }
}

@keyframes hatTrickGlow {
    0% {
        filter: drop-shadow(0px 0px 0.25rem rgb(255, 0, 242)) hue-rotate(0deg) saturate(2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem rgb(0, 128, 255)) hue-rotate(180deg) saturate(2);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem rgb(225, 0, 255)) hue-rotate(360deg) saturate(2);
    }
}