.digitalClock {
    position: relative;
    padding-top: 8px;
    .counter {
        display: block;
        text-align: center;
    }
    &.myTurn-true {
        color: #0F0;
        // animation: flickerGlowGreen 1.5s infinite alternate;
        text-shadow: 0 0 5px #006400, 0 0 10px #006400, 0 0 15px #009900, 0 0 20px #009900, 0 0 25px #009900;
    }
    &.myTurn-false {
        color: #FF0000;
        // animation: flickerGlow 1.5s infinite alternate;
        text-shadow: 0 0 5px #B22222, 0 0 10px #B22222, 0 0 15px #D43A3A, 0 0 20px #D43A3A, 0 0 25px #D43A3A;
    }
}

@keyframes flickerGlowGreen {
    0% {
        text-shadow: 0 0 6px #004d00, 0 0 12px #004d00, 0 0 18px #007300, 0 0 23px #007300, 0 0 29px #007300;
    }
    100% {
        text-shadow: 0 0 5px #006400, 0 0 10px #006400, 0 0 15px #009900, 0 0 20px #009900, 0 0 25px #009900;
    }
}

@keyframes flickerGlowRed {
    0% {
        text-shadow: 0 0 6px #990000, 0 0 12px #990000, 0 0 18px #B21A1A, 0 0 23px #B21A1A, 0 0 29px #B21A1A;
    }
    100% {
        text-shadow: 0 0 5px #B22222, 0 0 10px #B22222, 0 0 15px #D43A3A, 0 0 20px #D43A3A, 0 0 25px #D43A3A;
    }
}