.rank-bar {
    position: relative;
    width: 100%;
    height: 30px;
    background: linear-gradient(to right, #6b5600, #d4af37);
    border-radius: 4px;
    display: flex;
    align-items: center;
    background: #0000001f;
    font-family: "PT Serif";
    margin-bottom: 1rem;
  }
  
  .rank-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .rank-labels span {
    color: white;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    &.pointer {
        &:hover, &:active, &:focus {
            cursor: pointer;
            .hover-label {
                width: auto;
                height: auto;
                padding: 0.5rem;
                clip: unset;
                top: 0px;
                left: 50%;
                transform: translate3d(-50%, -100%, 0px);
                background-color: #b2722c;
                border-radius: 4px;
            }
        }
    }
  }
  
  .rank-marker {
    position: absolute;
    top: 50%;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8);
    animation: pulse 1.5s infinite;
    transition: all 1s;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8);
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 15px 8px rgba(255, 255, 255, 0.5);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8);
    }
  }
  