@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Bold.eot');
    src: url('PTSerif-Bold.eot?#iefix') format('embedded-opentype'),
        url('PTSerif-Bold.woff2') format('woff2'),
        url('PTSerif-Bold.woff') format('woff'),
        url('PTSerif-Bold.ttf') format('truetype'),
        url('PTSerif-Bold.svg#PTSerif-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Italic.eot');
    src: url('PTSerif-Italic.eot?#iefix') format('embedded-opentype'),
        url('PTSerif-Italic.woff2') format('woff2'),
        url('PTSerif-Italic.woff') format('woff'),
        url('PTSerif-Italic.ttf') format('truetype'),
        url('PTSerif-Italic.svg#PTSerif-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-BoldItalic.eot');
    src: url('PTSerif-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('PTSerif-BoldItalic.woff2') format('woff2'),
        url('PTSerif-BoldItalic.woff') format('woff'),
        url('PTSerif-BoldItalic.ttf') format('truetype'),
        url('PTSerif-BoldItalic.svg#PTSerif-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'PT Serif';
    src: url('PTSerif-Regular.eot');
    src: url('PTSerif-Regular.eot?#iefix') format('embedded-opentype'),
        url('PTSerif-Regular.woff2') format('woff2'),
        url('PTSerif-Regular.woff') format('woff'),
        url('PTSerif-Regular.ttf') format('truetype'),
        url('PTSerif-Regular.svg#PTSerif-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.pt-serif {
    font-family: 'PT Serif' !important;
}
