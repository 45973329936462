// Hud Element Vars

$elementWidth: 5rem;
$verticalOffset: 3.875rem;
$largScreenInnerOffset: 2%;

// Player Elements

.player-elements {

    position: absolute;
    top: $verticalOffset;
    left: 0px;
    width: 100%;
    height: calc(100% - $verticalOffset);
    overflow: hidden;
    // border: 1px solid red;

    // Hud Elements

    #hud-elements {

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 5;

        // Energy Meter

        .energy-meter {

            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            z-index: 9999999;

            &.player {
                bottom: calc(50% + -110px);
                left: $largScreenInnerOffset * 2;
                @media screen and (min-width: 768px) {
                    border-radius: 5px;
                    bottom: $largScreenInnerOffset;
                    left: $largScreenInnerOffset;
                }
            }

            &.opponent {
                top: calc(50% + -110px);
                left: $largScreenInnerOffset * 2;
                @media screen and (min-width: 768px) {
                    border-radius: 5px;
                    top: $largScreenInnerOffset;
                    left: $largScreenInnerOffset;
                    .energy-label {
                        margin-top: 1rem;
                        margin-bottom: -0.75rem;
                    }
                }
            }
            .energy-container {
                width: $elementWidth;
                padding: 0.5rem;
                display: flex;
                flex-direction: row;
                justify-content: center;
                & > div {
                    height: 100%;
                    width: 100%;
                    img {
                        width: 100%;
                        height: 40px;
                        object-fit: contain;
                    }
                }
                .full {
                    filter: drop-shadow(0px 0px 10px rgba(255, 215, 0, .5));
                }
            }
        }

        // Power Circles

        .power-circles {

            position: absolute;
            width: 150px;
            display: flex;
            justify-content: center;
            align-items: center;

            .power-label {
                padding: 0.5rem;   
                text-shadow: 
                    0px 3px 4px rgba(0, 0, 0, 1),
                    0px 3px 4px rgba(0, 0, 0, 1),
                ;
            }

            &.opponent {
                right: $largScreenInnerOffset * 2;
                top: 50%;
                transform-origin: top right;
                transform: translateY(-100%) scale(0.55);
                @media screen and (min-width: 768px) {
                    right: $largScreenInnerOffset / 8;
                    top: 1rem;
                    transform: scale(1);
                }
                .power-label {
                    padding-top: 0.75rem;
                    padding-bottom: 1.5rem;
                    margin-bottom: -1.55rem;
                    margin-top: 0px;
                }
            }
            &.player {
                right: $largScreenInnerOffset * 2;
                bottom: 50%;
                transform-origin: bottom right;
                transform: translateY(100%) scale(0.55);
                @media screen and (min-width: 768px) {
                    right: $largScreenInnerOffset / 8;
                    bottom: 1.5rem;
                    transform: scale(1);
                }
                .power-label {
                    padding-bottom: 0rem;
                    padding-top: 2.25rem;
                    margin-top: -1.55rem;
                }
            }
            
        }

        // Points
        .points {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            z-index: 9999999;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.25rem;
            padding: 0.5rem;
            min-width: 75px;
            @media screen and (min-width: 640px) {
                min-width: 80px;
            }
            &.opponent {
                left: $largScreenInnerOffset * 2;
                top: calc(50% + -44px);
                @media screen and (min-width: 768px) {
                    left: $largScreenInnerOffset;
                    top: 225px;
                }    
            }
            &.player {
                left: $largScreenInnerOffset * 2;
                bottom: calc(50% + -43px);
                @media screen and (min-width: 768px) {
                    left: $largScreenInnerOffset;
                    bottom: 225px;
                }
            }
        }

    }

    // Hud Elements Foreground

    #hud-elements-fg {

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;

        // Abilities Played

        .abilities-played {
            position: absolute;
            transform: translateY(-50%);
            left: 0rem;
            top: calc(50% - 2px);
            max-height: 202px;
            padding: 1rem;
            width: 80px;   
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(10px);
            border-radius: 5px;
            z-index: 9999999;
            display: none;
            flex-direction: column;
            z-index: 10;
            overflow: hidden;
            @media screen and (min-width: 768px) {
                left: $largScreenInnerOffset;
                height: calc(100vh - 670px);
                max-height: 350px;
                border-radius: 5px;
                display: flex;
            }
            @media screen and (max-height: 819px) {
                display: none;
            }
            .opponent-abilities, .my-abilities {
                /* Hide Scrollbar */
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow: -moz-scrollbars-none;
                overflow: scroll;
                height: 50%;
                overflow: auto;
                display: flex;
            }
            .opponent-abilities {
                justify-content: flex-end;
                flex-direction: column;
                align-items: flex-end;
                padding-bottom: 1rem;
                border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                margin-bottom: 0.25rem;
            }
            .my-abilities {
                margin-top: 0.25rem;
                justify-content: start;
                flex-direction: column-reverse;
                align-items: flex-start;
                padding-top: 1rem;
                border-top: 1px solid rgba(255, 255, 255, 0.2);
            }
            .ability-icon-container {
                width: 100%;
                .ability {
                    width: 40px;
                    height: 40px;
                    margin-top: 3px;
                    margin-bottom: 3px;
                    .ability-icon {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        padding: 0.4rem;
                        svg {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            path {
                                fill: #fff;
                            }
                        }
                    }
                }
            }
        }

        // Turn Indicator

        .turn-indicator {
            display: none;
            position: absolute;
            right: 2.25rem;
            top: 50%;
            transform: translateX(50%) translateY(calc(-50% - 1rem));
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(10px);
            border-radius: 50%;
            z-index: 9999999;
            $turnIndicatorWidth: 100px;
            width: $turnIndicatorWidth;
            height: $turnIndicatorWidth;
            @media screen and (min-height: 769px) {
                right: 5rem;
                $turnIndicatorWidth: 220px;
                width: $turnIndicatorWidth;
                height: $turnIndicatorWidth;
            }
            @media screen and (min-height: 900px) {
                $turnIndicatorWidth: 250px;
                width: $turnIndicatorWidth;
                height: $turnIndicatorWidth;
            }
            .indicator {
                width: 100%;
                transition: all 3s cubic-bezier(0.25, 1, 0.5, 1);
                transform: rotate(180deg) scale(0.95) translateY(1rem);
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
            }
            .dial {
                transition: all 2s cubic-bezier(0.25, 1, 0.5, 1);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%) rotate(180deg) scale(0.8);
                width: 100%;
                height: 100%;
                object-fit: contain;
                filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
            }
            &.your-turn {
                .indicator {
                    transform: rotate(360deg) scale(0.95) translateY(-1rem);
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
                }
                .dial {
                    transform: translateX(-50%) translateY(-50%) rotate(0deg) scale(0.8);
                    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
                }
            }

        }

        // Turn Clock

        .turn-clock {
            position: absolute;
            background-color: #0000006d;
            backdrop-filter: blur(10px);
            z-index: 9999999;
            border-radius: 5px;
            width: $elementWidth;    
            right: $largScreenInnerOffset * 2;
            transform: translateY(-50%);
            top: 50%;

            @media screen and (min-width: 768px) {
                right: $largScreenInnerOffset;
                transform: translateY(calc(-50% - 1.5rem));
            }

            .turn-timer-label {
                position: relative;
                margin-bottom: -0.5rem;
                display: none;
                @media screen and (min-width: 768px) {
                    display: block;
                }
            }
            .digitalClock {
                transform: translate3d(0, 1rem, 0);
                font-size: 2rem;
                @media screen and (min-width: 768px) {
                    font-size: 2.5rem !important;
                }
            }
            .turnCount {
                font-size: 1.25rem;
                margin-top: -1rem;
                @media screen and (min-width: 768px) {
                    font-size: 1.5rem !important;
                    margin-top: -1rem;
                }
            }
            .end-turn-button {
                transition: all 0.3s ease;
                &.pulse {
                    // bright gold
                    background-color: #ffc042;
                    animation: glowSelectedGold 1s infinite;
                    color: black;
                }
            }
        }

    }

    // Center Stage

    #center-stage {

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 2;
        border: 0px solid green;
    }

    // Match Message Stage
    #match-message {

        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        border: 0px solid green;

        // Ability Played Flyby Text

        .ability-played-container, .your-turn-container, .evaded-container {
            position: absolute;
            top: 0px;
            left: 50%;
            width: calc(100% - $elementWidth);
            height: calc(100% - 7rem);
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50%);
            h1 {
                text-align: center;
                font-style: italic;
                transform:  translateY(calc(50% + 30px));
                line-height: 0.85;
                font-size: 2.5rem;
                @media screen and (min-width: 768px) {
                    line-height: 0.85;
                    font-size: 7rem;
                } 
            }
        }
    }

    // Player Cards

    .player-cards {

        position: absolute;
        width: calc(100% - 2rem);
        left: 50%;
        transform: translate3d(-50%, 0, 0);
        min-height: 50px;
        z-index: 12;
        // border: 1px solid red;

        @media screen and (min-width: 768px) {
            width: calc(100% - 250px);
        }

        &.top {
            top: 0px;

            // FX Adjustments
            .laser-beam {
                position: absolute;
                top: 50%;
                left: 50%;
                transform-origin: top center;
                transform: translateX(-50%) rotate(3deg);
            }

            // TODO: This card count display looks cool, but when using a laser, the tilt calculation
            //       needs to also take into account the parent card transform (tilt) and adjust accordingly.
            // &.card-count-3 {
            //     .player-card {
            //         &:nth-child(1) {
            //             transform-origin: top right;
            //             transform: rotate(10deg) translateX(2rem);
            //         }
            //         &:nth-child(3) {
            //             transform-origin: top left;
            //             transform: rotate(-10deg) translateX(-2rem);
            //         }
            //     }
            // }
        }

        &.bottom {
            bottom: 0px;
             
            // FX Adjustmnets
            .laser-beam {
                position: absolute;
                bottom: 50%;
                left: 50%;
                transform-origin: bottom center;
                transform: translateX(-50%) rotate(-3deg);
            }

        }

        &.top, &.bottom {
            display: flex;
            justify-content: center;
            .player-card {
                max-height: none;
                max-width: 33%;
                @media screen and (min-width: 768px) {
                    max-width: 225px;
                }
            }
        }

    }

}








// Life Bar
.life-bar {

    position: absolute;
    left: 0px;
    width: 100%;
    height: 1.15rem;
    background-color: rgb(18 12 6 / 0%);
    backdrop-filter: blur(1.25rem);

    .life-bar-inner {
        display: flex;
        justify-content: end;
        align-items: center;
        transition: all 0.75s ease;
        background-color: rgba(255, 255, 255, 0.20);
        backdrop-filter: blur(1.25rem);
        height: 100%;
        .gladiator-arena {
            position: absolute;
            top: 0px;
            right: 0.5rem;
            transform: translateY(calc(50% - 2px));
            text-shadow: 
                0px 3px 4px rgba(0, 0, 0, 0.5),
                0px 3px 4px rgba(0, 0, 0, 0.5),
            ;
        }
        &.heal {
            background-color: rgba(0, 255, 13, 0.5);
        }
        &.wounded {
            background-color: rgba(255, 0, 0, 0.5);
        }    
    }

    &.opponent {
        top: 124px;
    }

    &.player {
        bottom: 60px;
    }

}




// End Turn Button
.end-turn-btn {
    position: absolute;
    top: 50%;
    right: 150px;
}



// Win Lose Message

.win-lose-container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    background-color: rgb(39 31 21 / 60%);
    .win-lose {
        padding-left: 2rem;
        padding-right: 2rem;
        background: #00000054;
        max-width: 800px;
        backdrop-filter: blur(1rem);
        -webkit-backdrop-filter: blur(1rem);
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
        -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    }
    h1 {
        font-size: 2rem;
        text-align: center;
        line-height: 0.85;
        font-style: italic;
    }
    p {
        font-size: 1rem !important;
    }
    .battle-summary {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @media screen and (min-width: 768px) {
        h1 {
            font-size: 5rem;
        }
        p {
            font-size: 1.25rem !important;
        }
        .battle-summary {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }
}