$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="chaotic-lash"] {
            pointer-events: none !important;
            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;
                .fx {
                    transform: translateX(2rem);
                    &.chaotic-lash {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }

            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashMelee 0.5s infinite forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        .fx {
                            &.chaotic-lash {
                                transform: translate3d(-50%, -50%, 0) scale(3) rotate(180deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/chaotic-lash/chaotic-lash-a.png');
                            }
                        }
                    }
                }
            }
            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashMelee 0.5s infinite forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        .fx {
                            &.chaotic-lash {
                                transform: translate3d(-50%, -50%, 0) scale(3) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/chaotic-lash/chaotic-lash-a.png');
                            }
                        }
                    }
                }
            }

        }

    }
}

// Animations