.zero-x-modal {
    --background: transparent;
    .ion-page {
        // border: 1px solid #976b38ba;
    }
    ion-header {
        --background-color: transparent;
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);
    }
    ion-toolbar {
        --background: transparent;
    }
    ion-content {
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);
    }
    ion-footer {
        --background-color: transparent;
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);    
        padding: 0.5rem;
    }
}

.zero-x-modal-coin {
    @media screen and (min-width: 768px) and (min-height: 768px) {
        --width: 400px !important;
        --height: 400px !important;
        --border-radius: 1rem;
    }
}

.token-logo {
    $size: 26px;
    width: $size;
    height: $size;
    display: block;
}

.swap-amount {
    font-size: 2.2rem;
}