.choose-token-modal {
    --background: transparent;
    --width: 800px !important;
    --min-height: 78vh !important;
    .ion-page {
        // border: 1rem solid #976b3800;
    }
    ion-header {
        --background-color: transparent;
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);
    }
    ion-toolbar {
        --background: transparent;
    }
    ion-content {
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);
    }
    ion-footer {
        --background-color: transparent;
        --background: transparent;
        background: rgba(18,12,6,1);
        background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);    
        padding: 0.5rem;
    }
}
