.gladiator-page.shop {
    .player-card {
        transition: all 0.3s;
        min-width: 300px;
        max-width: 350px;
        @media screen and (max-height: 990px) {
            max-width: 350px;
        }
        @media screen and (max-height: 860px) {
            max-width: 300px;
        }
    }
    .slide-content {
        margin: 1rem;
        padding: 1rem;
    }
    .swiper-pagination {
        position: absolute;
        top: 0rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
        .swiper-pagination-bullet {
            $wh: 20px;
            transition: all 0.3s ease-in-out;
            width: $wh;
            height: $wh;
            border-radius: 1rem;
            background-color: var(--gladiator-gold);
            display: inline-block;
            margin: 0 0.25rem;
            &:hover, &:focus, &:active {
                cursor: pointer;
                background-color: var(--gladiator-gold-dark);
            }
            &.swiper-pagination-bullet-active {
                width: 100px;
            }
        }
    }

    .shop-item-container {
        min-height: 560px;
    }

    .page-content {
        padding-top: calc(env(safe-area-inset-top) + 60px);
        @media screen and (min-width: 64em) {
            padding-top: calc(env(safe-area-inset-top) + 75px);
        }
        .scrollable-page-content {
            @media screen and (min-width: 64em) {
                height: calc(100vh - 200px);
            }
        }
    }

}
