$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="dominance-protocol"] {
            .aspect-ratio--object {
                animation: flashDominanceProtocol 5s infinite forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                // animation-delay: 450ms;

                .fx {
                    transform: translateX(2rem);

                    &.dominance-protocol {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-50%, -50%, 0) scale(2.5) rotate(0);
                        width: 80%;
                        height: 100%;
                        background-image: url('#{$spacesStorage}/dominance-protocol/dominance-protocol-a.png');
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        // bounce in animation
                        // animation: bounceCarrotIn 1.5s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashDominanceProtocol {
    0% {
        filter: drop-shadow(0px 0px 0.25rem #ffffff) sepia(1) hue-rotate(329deg) saturate(400%)
    }

    50% {
        filter: drop-shadow(0px 0px 1rem #ffffff) sepia(1) hue-rotate(329deg) saturate(400%);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem #ffffff) sepia(1) hue-rotate(329deg) saturate(400%);
    }
}