$circle-size: 100px;

.power-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc($circle-size + 1rem);
    height: calc($circle-size + 1rem);
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    .defense {
        transition: all 0.5s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: $circle-size;
        height: $circle-size;
        .track {
            color: rgba(0, 0, 0, 0.25);
        }
        .progress {
            color: #F2D06A;
        }
    }

    .health {
        transition: all 0.5s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0) scale(0.85);
        width: $circle-size;
        height: $circle-size;
        filter: drop-shadow(0px 0px 0.5rem #000);
        .track {
            color: rgba(0, 0, 0, 0.25);
        }
        .progress {
            color: red;
        }
    }

    &.heal {
        .defense{
            filter: drop-shadow(0px 0px 1rem #F2D06A);
        }
        .health{
            filter: drop-shadow(0px 0px 1rem green);
        }
    }

    &.damage {
        .defense{
            filter: drop-shadow(0px 0px 1rem red);
        }
        .health{
            filter: drop-shadow(0px 0px 1rem red);
        }
    }

    @keyframes progressAnimation {
        from {
            stroke-dashoffset: 0;
        }

        to {
            stroke-dashoffset: var(--offset);
        }
    }

    .circle-progress-bar .progress {
        transition: stroke-dashoffset 1s ease-in-out;
    }

    .value {
        font-size: 2rem;
    }

}