/*
Space Backdrop
*/

#play-backdrop {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    & > .aspect-ratio {
        width: 100%;
        height: 100%;
        transition: all 1s ease;
        transform: scale(1.75);
        @media screen and (min-width: 500px) {
            transform: scale(1.5);
        }
        @media screen and (min-width: 800px) {
            transform: scale(1.25);
        }
        @media screen and (min-width: 900px) {
            transform: scale(1.25);
        }
        .aspect-ratio--object {
            animation: tilt 30s ease infinite alternate; /* Adjust the duration to make the rotation faster or slower */
            // background-image: url('../collections/cosmicmuffins/backdrops/starfield/space1.jpg');
            background-position: center;
        }
    }
    &:not(.saturated) * {
        filter: saturate(0);
    }
}

@keyframes tilt {
    0% {
        transform: rotate(-15deg) translateX(0%) translateY(0%) translateZ(0);
    }
    100% {
        transform: rotate(15deg) translateX(0%) translateY(0%) translateZ(0);
    }
}

.space-backdrop {
    --background: transparent;
}

.space-stars {
    z-index: 3;
}

.space-backdrop:before {
    content: '' !important;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // background-image: url('../collections/cosmicmuffins/backdrops/starfield/space1.jpg');
}

#starfield-container-connect, #starfield-container-play {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 1;
    &:not(.saturated) * {
        filter: saturate(0);
    }
}

// Outlines
.neon-outline {
    width: calc(100% - 2rem);
    height: calc(100% - 2rem - 62px);
    position: absolute;
    top: calc(1rem + 62px);
    left: 1rem;
    border: 1px solid #c500ff;
    z-index: 0;
    animation: borderhud 0.8s linear infinite alternate;
}

.neon-outline-2 {
    width: calc(100% - 3rem);
    height: calc(100% - 3rem - 62px);
    position: absolute;
    top: calc(1.5rem + 62px);
    left: 1.5rem;
    border: 1px dotted #c500ff;
    z-index: 0;
    animation: borderhud 1.8s linear infinite alternate;
}

.neon-outline-3 {
    width: calc(100% - 4rem);
    height: calc(100% - 4rem - 62px);
    position: absolute;
    top: calc(2rem + 62px);
    left: 2rem;
    border: 1px dotted #c500ff;
    z-index: 0;
    animation: borderhud 3.8s linear infinite alternate;
}

@keyframes borderhud {
    from {
        border: 1px solid #c500ff;
    }

    to {
        border: 1px solid #00cbff;
    }
}