$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/cosmicmuffins/fx';

// Player Cards FX

.player-cards {
    .player-card {

        &[data-battle-fx="muffin-melee-attack"] {
            pointer-events: none !important;
            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;
                .fx {
                    transform: translateX(2rem);
                    &.muffin-melee-attack {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }

            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashMelee 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.muffin-melee-attack {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(180deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/muffin-melee/impact.png');
                            }
                        }
                    }
                }
            }
            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashMelee 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.muffin-melee-attack {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/muffin-melee/impact.png');
                            }
                        }
                    }
                }
            }

        }


    }
}

// Animations

@keyframes flashMelee {
    0% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem orange);
    }

    50% {
        filter: brightness(300%) drop-shadow(0px 0px 1rem white);
    }

    100% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem orange);
    }
}