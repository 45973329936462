$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/sacredmoths/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="galactic-dust-beam"] {

            &.player {
                .aspect-ratio--object {
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .fx {
                        &.galactic-dust-beam {
                            position: absolute;
                            border: 0px solid red;
                            left: 50%;
                            top: 50%;
                            z-index: 999999;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            animation: dustLaser, dustEffect 0.25s infinite linear;
                        }
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashDustLaser 0.5s linear, hover 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.galactic-dust-beam {
                                transform: translate3d(-50%, -50%, 0) scale(0.75) rotate(0deg);
                                width: 889px;
                                height: 500px;
                                background-image: url('#{$spacesStorage}/galactic-dust-beam/player/dust.png');
                            }
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashDustLaser 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.galactic-dust-beam {
                                transform: translate3d(-50%, -50%, 0) scale(0.75) rotate(0deg);
                                width: 889px;
                                height: 500px;
                                background-image: url('#{$spacesStorage}/galactic-dust-beam/opponent/dust.png');
                            }
                        }
                    }
                }
            }

            &.opponent {
                .aspect-ratio--object {
                    animation: flashDustLaser 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
    
                    .fx {
                        &.galactic-dust-beam {
                            position: absolute;
                            border: 0px solid red;
                            left: 50%;
                            top: 50%;
                            z-index: 999999;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            animation: dustLaser, dustEffect 0.25s infinite linear;
                        }
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        .fx {
                            &.galactic-dust-beam {
                                transform: translate3d(-52%, -38%, 0) scale(1.25) rotate(180deg);
                                width: 400px;
                                height: 300px;
                                background-image: url('#{$spacesStorage}/galactic-dust-beam/opponent/dust.png');
                            }
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        .fx {
                            &.galactic-dust-beam {
                                transform: translate3d(-50%, -50%, 0) scale(0.75) rotate(180deg);
                                width: 889px;
                                height: 500px;
                                background-image: url('#{$spacesStorage}/galactic-dust-beam/player/dust.png');
                            }
                        }
                    }
                }
            }

            .laser-beam {
                position: absolute;
                width: 10px;
                height: 0%;
                background-size: 200% 200%;
                border-radius: 100px;
                // z-index: 99999;
                filter: blur(10px) brightness(2);
            
                &.attack {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgb(105, 105, 105), rgb(255, 255, 255));
                    animation: dustLaser 1s infinite, gradientUp 1s linear infinite;
            
                    &.animate__fadeOut {
                        display: none !important;
                    }
                }
            
                &.incoming {
                    background: linear-gradient(0deg, rgb(255, 255, 255), rgb(114, 114, 114), rgba(255, 255, 255, 0.6));
                    animation: dustLaser 1s infinite, gradientDown 1s linear infinite;
            
                    &.animate__fadeOut {
                        display: none !important;
                    }
                }
            }

        }

    }
}

// Animations

@keyframes dustLaser {
    0% {
        box-shadow: 0px 0px 20px 5px rgb(255, 255, 255);
    }

    50% {
        box-shadow: 0px 0px 20px 25px rgb(255, 255, 255);
    }

    100% {
        box-shadow: 0px 0px 20px 5px rgb(141, 141, 141);
    }
}

@keyframes dustEffect {
    0% {
        filter: hue-rotate(0deg) drop-shadow(0px 0px 0.25rem #FFF);
    }

    50% {
        filter: hue-rotate(180deg) drop-shadow(0px 0px 1rem #c9c9c9);
    }

    100% {
        filter: hue-rotate(360deg) drop-shadow(0px 0px 0.25rem #FFF);
    }
}

@keyframes flashDustLaser {
    0% {
        filter: saturate(0%) drop-shadow(0px 0px 0.25rem rgb(111, 111, 111)) hue-rotate(0deg);
    }

    50% {
        filter: saturate(0%) drop-shadow(0px 0px 1rem white) hue-rotate(180deg);
    }

    100% {
        filter: saturate(0%) drop-shadow(0px 0px 0.25rem rgb(111, 111, 111)) hue-rotate(360deg);
    }
}