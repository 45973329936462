$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

.checkmark {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    stroke-width: 6;
    stroke: #FFF;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #976b38;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #976b38;
    fill: none;
    animation: stroke .6s $curve forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s $curve .8s forwards;
}