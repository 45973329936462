.delay-500ms {
    animation-delay: 500ms;
}

.pointer-events-none {
    pointer-events: none !important;
}

.green {
    color: #0F0;
}