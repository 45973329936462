#panel-settings {
  
  .music-player {
    .rhap_additional-controls,
    .rhap_volume-controls {
      // display: none;
    }
    svg {
      path {
        fill: rgb(225 255 255 / 95%);
      }
    }
    .rhap_main-controls {
      margin-left: 0.5rem;
    }
    .rhap_volume-container {
      justify-content: center;
    }
  
    .rhap_main {
      display: flex;
      flex-direction: column;
    }
  
    .rhap_progress-section {
      flex: 50 auto;
    }
  
    .rhap_controls-section {
      margin-top: 0px !important;
    }
  
    .rhap_container {
      background-color: transparent;
      padding: 0px;
      box-shadow: none;
    }
  
    .rhap_time {
      color: #ccc;
    }
  
    .rhap_progress-container {
      margin: 0 calc(12px + 1%);
    }
  
    .rhap_progress-bar {
      background-color: #444;
    }
  
    .rhap_progress-filled {
      background-color: #e0e0e0;
    }
  
    .rhap_progress-bar-show-download {
      background-color: rgba(68, 68, 68, 0.5);
    }
  
    .rhap_download-progress {
      background-color: #5c5c5c;
    }
  
    .rhap_progress-indicator {
      background: #e0e0e0;
      box-shadow: rgba(224, 224, 224, 0.5) 0 0 5px;
    }
  
    .rhap_repeat-button,
    .rhap_main-controls-button,
    .rhap_volume-button {
      color: #e0e0e0;
    }
  
    .rhap_volume-bar {
      background: #444;
    }
  
    .rhap_volume-indicator {
      background: #e0e0e0;
      box-shadow: rgba(224, 224, 224, 0.5) 0 0 3px;
    }
  
    .rhap_volume-filled {
      background-color: #e0e0e0;
    }
  
    /* Utils */
    .rhap_button-clear:hover {
      opacity: 0.7;
    }
    .rhap_button-clear:active {
      opacity: 0.85;
    }
  }

}