// Player Cards FX

.player-cards {
    .player-card {

        &[data-battle-fx="buff-bash"] {
            transition: all 0.65s ease;
            pointer-events: none !important;
            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 1s;
                .fx {
                    transform: translateX(2rem);
                    &.buff-bash {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        img {
                            width: 100%;
                            height: 100%;    
                            z-index: 999999;
                            object-fit: contain;
                        }
                    }
                }
            }

            &.player {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashBuffBash 1.5s ease-out forwards;
                        .fx {
                            &.buff-bash {
                                transform: translate3d(-50%, -50%, 0) scale(2) rotate(0deg);
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .flip-card-inner {
                        opacity: 0;
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashBuffBash 1.5s ease-out forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) both infinite;
                        .fx {
                            &.buff-bash {
                                -webkit-filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 400%;
                                height: 400%;
                            }
                        }
                    }
                }
            }
            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashBuffBash 1.5s ease-out forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) both infinite;
                        .fx {
                            &.buff-bash {
                                -webkit-filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                filter: brightness(0.65) sepia(1) hue-rotate(166deg) saturate(2);
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 400%;
                                height: 400%;
                            }
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashBuffBash 1.5s ease-out forwards;
                        .fx {
                            &.buff-bash {
                                transform: translate3d(-50%, -50%, 0) scale(2) rotate(0deg);
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .flip-card-inner {
                        opacity: 0;
                    }
                }
            }

        }


    }
}

// Animations

@keyframes flashBuffBash {
    0% {
        -webkit-filter: brightness(300%) drop-shadow(0px 0px 3rem #ff00e6);
        filter: brightness(300%) drop-shadow(0px 0px 3rem #ff00e6);
    }

    50% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 3rem #00aaff);
        filter: brightness(100%) drop-shadow(0px 0px 3rem #00aaff);
    }

    100% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 3rem #ff00e6);
        filter: brightness(100%) drop-shadow(0px 0px 3rem #ff00e6);
    }
}

// Quick Zoom In animation

@keyframes zoomIn {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}