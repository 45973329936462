$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/sacredmoths/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="stardust-revive"] {
            .aspect-ratio--object {
                animation: flashRevive 0.5s linear infinite, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;

                .fx {
                    transform: translateX(2rem);

                    &.stardust-revive {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-45%, -38%, 0) scale(3) rotate(0deg);
                        width: 100%;
                        height: 100%;
                        background-image: url('#{$spacesStorage}/stardust-revive/stardust-pop.png');
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        animation: flashRevive 0.5s linear infinite;
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashRevive {
    0% {
        filter: drop-shadow(0px 0px 0.25rem #ffffff) grayscale(100%) sepia(1) hue-rotate(0deg) saturate(0%) brightness(2);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem #c1c1c1) grayscale(100%) sepia(1) hue-rotate(0deg) saturate(0%) brightness(2);
    }

    100% {
        filter: drop-shadow(0px 0px 0.25rem #ffffff) grayscale(100%) sepia(1) hue-rotate(0deg) saturate(0%) brightness(2);
    }
}
