.coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2rem);
    margin: 0px;
    transform: scale(1.05);
    border-radius: 8px;
}

.collectible-info {
    padding: 1rem 2rem;
    position: relative;
    background-color: rgba(46,29,17,0.85);
    -webkit-backdrop-filter: blur(1.25rem);
    backdrop-filter: blur(1.25rem);
    border-radius: 8px;
}