.dot {
    pointer-events: none;
    position: absolute;
    min-width: 250px;
    max-width: 310px;
    z-index: 999999999;
    transform: translateY(-50%);
    
    .tooltip {
      opacity: 0;
      transition: all 350ms ease-in-out;
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(10px);
      border: 1px solid rgba(255, 255, 255, 0.595);
      border-radius: 5px;
      animation-duration: 350ms;
      animation-timing-function: ease-in-out;
      color: #FFF;
    }
  
    &.appear .tooltip {
      opacity: 1;
    }
  }