.shadow-5-white {
    box-shadow: 4px 4px 8px 0 rgba(189, 189, 189, 0.2);
}

.shadow-5-black {
    box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.release-notes-modal-content {
    position: relative;
    .segment-container {
        position: sticky;
        left: 0px;
        top: 0px;
        width: calc(100% - 2rem);
        z-index: 9;
        background-color: #121212;
        padding-bottom: 0.5rem;
    }
}

.underline-hover {
    text-wrap: pretty !important;
    &:active, &:focus {
        text-decoration: none;
    }
}