.sound-bars {
    position: relative;
    margin: 0;
    width: 1rem;
    height: 1rem;
}

.bar {
    background: #ffffff;
    bottom: 1px;
    height: 3px;
    position: absolute;
    width: 3px;
    animation: sound 0ms -800ms linear infinite alternate;
}

@keyframes sound {
    0% {
        opacity: .35;
        height: 3px;
    }

    100% {
        opacity: 1;
        height: 1rem;
    }
}

.bar:nth-child(1) {
    left: 1px;
    animation-duration: 474ms;
}

.bar:nth-child(2) {
    left: 5px;
    animation-duration: 433ms;
}

.bar:nth-child(3) {
    left: 9px;
    animation-duration: 407ms;
}

.bar:nth-child(4) {
    left: 13px;
    animation-duration: 458ms;
}

.bar:nth-child(5) {
    left: 17px;
    animation-duration: 400ms;
}