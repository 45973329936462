.connect-wallet-container,
.play-join-container,
.select-mode-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
}

.connect-wallet, .choose-mode {

    overflow: hidden;
    background: rgb(59, 14, 104);
    background: radial-gradient(circle, rgba(59, 14, 104, 1) 0%, rgba(26, 8, 78, 1) 100%);
    --width: 600px !important;
    --height: 600px !important;

    background: transparent;
    --background: transparent;
    --box-shadow: none;

    ion-content {
        --background: transparent;
        border: 0px solid #f729ff;
        box-shadow: none;

        .modal-content {
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            background-image: url('./../images/gladiator/connect-backdrop.png');
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            h1, h2, h3, h4, h5, h6, p {
                text-shadow: 
                    0px 5px 20px rgba(0, 0, 0, 0.8),
                    0px 5px 20px rgba(0, 0, 0, 0.8),
                    0px 5px 20px rgba(0, 0, 0, 0.8),
                    0px 5px 20px rgba(0, 0, 0, 0.8),
                    0px 5px 20px rgba(0, 0, 0, 0.8),
                ;
            }

        }
    }
}


.choose-mode {
    ion-content {
        .modal-content {
            background-image: url('./../images/gladiator/mode-backdrop.png');
            ion-button:not(.cancel) {
                height: auto;
                --pading-top: 1rem;
                --padding-bottom: 1rem;
            }
        }
    }
}