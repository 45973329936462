.colosseum-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('./../images/gladiator/colosseum-backdrop.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    transition: all 0.5s ease;
    backface-visibility: hidden;
    video, img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}

.backdrop-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    background: linear-gradient(90deg, rgba(46,29,17,0.90) 0%, rgba(46,29,17,0.0) 34%, rgba(46,29,17,0.0) 62%, rgba(46,29,17,0.90) 100%);
    &:before {
        content: "";
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 75px;
        background: linear-gradient(180deg, rgba(46,29,17,1) 0%, rgba(46,29,17,0) 100%);
    }
}
