.theme-bedlams {
    --theme-primary-color: #ff0000;
    --dark-primary-color: #970000;
    --overlay-color: rgba(46, 17, 17, 0.9);
    --transparent-color: rgba(46, 17, 17, 0);

    ion-header {
        background-color: rgba(var(--theme-primary-color), 0.2);
        border-bottom: 1px solid var(--theme-primary-color);
    }

    ion-button {
        &[color="primary"] {
            --border: 1px solid var(--theme-primary-color);
            --ion-color-base: var(--dark-primary-color) !important;
            --ion-color-base-rgb: 255, 50, 0 !important;
            --ion-color-contrast: #ffffff !important;
            --ion-color-contrast-rgb: 255, 255, 255 !important;
            --ion-color-shade: var(--dark-primary-color) !important;
            --ion-color-tint: var(--dark-primary-color) !important;
        }
    }

    .backdrop-overlay {
        background: linear-gradient(90deg, var(--overlay-color) 0%, var(--transparent-color) 34%, var(--transparent-color) 62%, var(--overlay-color) 100%);
    }

    .gladiator-top-border {
        border-top: 1px solid var(--theme-primary-color);
        background: linear-gradient(180deg, var(--overlay-color) 0%, var(--transparent-color) 90%);
    }

    .gladiator-bottom-border {
        border-bottom: 1px solid var(--theme-primary-color);
        background: linear-gradient(0deg, var(--overlay-color) 0%, var(--transparent-color) 90%);
    }

    .colosseum-backdrop {
        background-color: var(--transparent-color);
        background-image: none;
    }

}
