$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="thumper-bomb"] {

            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;
                .fx {
                    &.thumper-bomb {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        animation: thumperBombEffect 1s infinite linear;
                        mix-blend-mode: plus-lighter;
                    }
                }
            }

            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashThumperBomb 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.thumper-bomb {
                                transform: translate3d(-50%, -50%, 0) scale(1.5) rotate(0deg);
                                width: 400px;
                                height: 400px;
                                background-image: url('#{$spacesStorage}/thumper-bomb/opponent/thumper-bomb-a.png');
                            }
                        }
                    }
                }
            }

            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashThumperBomb 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.thumper-bomb {
                                transform: translate3d(-52%, -50%, 0) scale(1.5) rotate(180deg);
                                width: 400px;
                                height: 400px;
                                background-image: url('#{$spacesStorage}/thumper-bomb/opponent/thumper-bomb-a.png');                                
                            }
                        }
                    }
                }
            }
        }  

        .thumper-bomb-projectile {
            position: absolute;
            left: 0;
            top: 0;
            transform: translate3d(0%, 0%, 0);
            width: 90%;
            height: 90%;
            z-index: 999999;
            background-image: url('#{$spacesStorage}/thumper-bomb/player/thumper-bomb-projectilea.png');
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            &.ignite {
                animation: thumperBombEffect 0.25s infinite linear;
                transition: all 0.75s cubic-bezier(0.34, 1.24, 0.64, 1);    
            }
            &.explode {
                animation: none;
                transition: all 0.75s cubic-bezier(0.34, 1.24, 0.64, 1);
                filter: brightness(900%) saturate(0) drop-shadow(0px 0px 1rem #FFF);
            }
        }

    }
}

// Animations

@keyframes thumperBombGlow {
    0% {
        box-shadow: 0px 0px 20px 5px #ff6a00;
    }

    50% {
        box-shadow: 0px 0px 20px 25px #ddff00;
    }

    100% {
        box-shadow: 0px 0px 20px 5px #ff6a00;
    }
}

@keyframes thumperBombEffect {
    0% {
        filter: drop-shadow(0px 0px 1rem #ff6a00);
    }

    50% {
        filter: drop-shadow(0px 0px 1rem #fbff00);
    }

    100% {
        filter: drop-shadow(0px 0px 1rem #ff6a00);
    }
}

@keyframes flashThumperBomb {
    0% {
        filter: saturate(0%) drop-shadow(0px 0px 0.25rem #ff6a00) hue-rotate(0deg);
    }

    50% {
        filter: saturate(0%) drop-shadow(0px 0px 1rem #fbff00) hue-rotate(180deg);
    }

    100% {
        filter: saturate(0%) drop-shadow(0px 0px 0.25rem #ff6a00) hue-rotate(360deg);
    }
}