.gladiator-wallet-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    background: rgba(18,12,6,1);
    background: radial-gradient(circle, rgba(48,38,27,1) 0%, rgba(18,12,6,1) 100%);
    padding: 2.2rem;
    .gladiator-logo {
        transform-style: preserve-3d;
        transform: perspective(1000px);
        transition: all 150ms linear;
        margin: 0 auto;
        @media screen and (max-height: 932px) {
            width: 300px;
        }
        @media screen and (max-height: 896px) {
            width: 200px;
        }
        .left {
            position: absolute;
            top: -1.5rem;
            transform-origin: bottom center;
            animation: rotateLeft 1s forwards;
            opacity: 0;
            animation-delay: 0.75s;
            animation-fill-mode: forwards;
            transform: translateZ(-50px);
            filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
        }
        .right {
            position: absolute;
            top: -1.5rem;
            transform-origin: bottom center;
            animation: rotateRight 1s forwards;
            opacity: 0;
            animation-delay: 0.75s;
            animation-fill-mode: forwards;
            transform: translateZ(-50px);
            filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
        }
        .logo {
            position: relative;
            z-index: 1;
            transform: translateZ(50px);
            filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
        }
        img {
            transition: all 300ms ease;
        }
        &:hover {
            img {
                filter: drop-shadow(0px 0.5rem 00rem #0000005c) brightness(1.25) contrast(1.05);
            }
        }
    }
    &.choose-collection {
        .collection-btn {
            height: auto;
            img {
                max-height: 150px;
            }
        }
    }
}


#tsparticles {
    width: 100%;
    height: 100%;
    display: block;
    z-index: 20;
    position: absolute;
}

.welcome-block {
    height: calc(100% - 40px);
    display: flex;
    justify-content: center;
    align-items: center;
    .gladiator-welcome-container {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 4;
        padding: 1.5rem;
        padding-top: calc(env(safe-area-inset-top) + 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        
        .welcome-content-container {
            height: calc(100vh - 220px)
        }
    
        .welcome-content {
            height: 100%;
            padding: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            position: relative;
            border: 2px solid rgba(255, 255, 255, 0.15);
            background-color: #58493100;
            backdrop-filter: blur(1rem);
            border-radius: 1rem;

            &.about-true, &.mechanics-true {
                overflow: hidden;
                overflow-y: auto;
                border: 2px solid rgba(255, 255, 255, 0.15);
                background-color: #58493100;
                backdrop-filter: blur(1rem);
            }

            &::-webkit-scrollbar {
                width: 10px;
            }
            
            &::-webkit-scrollbar-track {
                background: transparent; 
            }
            
            &::-webkit-scrollbar-thumb {
                background: #584931; 
                border-radius: 0px;
            }
            
            &::-webkit-scrollbar-thumb:hover {
                background:transparent; 
            }
            scrollbar-color: #584931 transparent;
            scrollbar-base-color: transparent;
            scrollbar-face-color: transparent;
            scrollbar-3dlight-color: transparent;
            scrollbar-highlight-color: transparent;
            scrollbar-track-color: transparent;
            scrollbar-arrow-color: transparent;
            scrollbar-shadow-color: transparent;
            scrollbar-dark-shadow-color: transparent;
            max-height: 700px;
            background-size: cover;
            background-position: center;
            .cards {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0) scale(0.9);
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                @media screen and (max-width: 640px) {
                    transform: translate3d(-50%, -50%, 0) scale(1.4);
                }
            }
        }
    
        .play-btn {
            transform: translateY(calc(100% - 1rem));
        }
    
        .greeting {
            position: absolute;
            top: 2rem;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            filter: drop-shadow(0px 0.5rem 0.25rem #000);
            text-align: center;
            @media screen and (max-width: 768px) {
                top: 8%;
            }
        }
    
        .info-overlay {
            position: absolute;
            bottom: 2rem;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            filter: drop-shadow(0px 0.5rem 0.25rem #000);
            text-align: center;
            z-index: 999;
            @media screen and (max-width: 768px) {
                bottom: 8%;
            }
        }

        .about-content {
            position: relative;
            z-index: 999;
            background-color: #58493100;
            backdrop-filter: blur(2.5rem);
            padding: 1rem;
            height: auto;
            width: 100%;
            @media screen and (min-width: 768px) {
                padding: 1rem 2rem;
            }
        }
    
        .change-theme-btn {
            position: absolute;
            bottom: 2rem;
            left: 50%;
            z-index: 120;
            filter: drop-shadow(0px 0.5rem 0.25rem #000);
            transform: translateX(-50%);
        }
    
    }
}

/* Left Card Rotation Animation */
@keyframes rotateLeft {
    from {
        transform: rotate(0deg) scale(0.9);
        opacity: 0;
    }
    to {
        transform: rotate(-12deg) scale(0.9);
        opacity: 1;
    }
}

/* Right Card Rotation Animation */
@keyframes rotateRight {
    from {
        transform: rotate(0deg) scale(0.9);
        opacity: 0;
    }
    to {
        transform: rotate(12deg) scale(0.9);
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: translateY(-50%) translateX(-50%) scale(1.2);
        opacity: 0.8;
    }
    50% {
        transform: translateY(-50%) translateX(-50%) scale(1.15);
        opacity: 1;
    }
    100% {
        transform: translateY(-50%) translateX(-50%) scale(1.2);
        opacity: 0.8;
    }
}
