$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/bedlams/fx';

// Player Card FX

#center-stage {

    .fx {

        &.apex-strike {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            animation: flashApexStrike 0.25s infinite forwards;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            mix-blend-mode: screen;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(180deg);
            }
        }

    }

}

.player-cards {
    .player-card {

        &[data-battle-fx="apex-strike"] {
            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashApexStrike 0.5s infinite forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: flashApexStrike 1s linear infinite forwards;
                        }
                    }
                    .aspect-ratio--object {
                        .fx {
                            &.apex-strike {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(0deg);
                                width: 110%;
                                height: 120%;
                                // background-image: url('#{$spacesStorage}/apex-strike/opponent/apex-strike-impact.png');  
                                background-size: 100% 100%;
                                animation: flashApexStrike 1s infinite forwards;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                top: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
            &.opponent {
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashApexStrike 0.5s infinite forwards, shake 0.5s cubic-bezier(.36, .07, .19, .97) infinite forwards;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;
                        .flip-card-inner {
                            animation: flashApexStrike 1s linear infinite forwards;
                        }
                    }
                    .aspect-ratio--object {
                        .fx {
                            &.apex-strike {
                                transform: translate3d(-50%, 0%, 0) scale(1) rotate(180deg);
                                width: 110%;
                                height: 120%;
                                // background-image: url('#{$spacesStorage}/apex-strike/opponent/apex-strike-impact.png');
                                background-size: 100% 100%;
                                animation: flashApexStrike 1s infinite forwards;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                bottom: 0px;
                                left: 50%; 
                            }
                        }
                    }
                }
            }
        }

    }
}

// Animations

@keyframes flashApexStrike {
    0% {
        filter: brightness(70%) contrast(2.5) sepia(1) hue-rotate(310deg) saturate(5) drop-shadow(0px 0px 0.25rem rgb(255, 0, 0));
    }

    50% {
        filter: brightness(100%) contrast(1) sepia(1) hue-rotate(310deg) saturate(5) drop-shadow(0px 0px 1rem rgb(255, 0, 0));
    }

    100% {
        filter: brightness(70%) contrast(2.5) sepia(1) hue-rotate(310deg) saturate(5) drop-shadow(0px 0px 0.25rem rgb(255, 0, 0));
    }
}