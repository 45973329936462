$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/buns/fx';

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="bushido-bun"] {
            pointer-events: none !important;
            transition: all 0.6s ease-in-out;
            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;
                .fx {
                    transform: translateX(2rem);
                    &.bushido-bun {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }

            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashBushidoBun 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.bushido-bun {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(180deg);
                                width: 100%;
                                height: 100%;
                                background-image: none;
                            }
                        }
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashBushidoBun 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.bushido-bun {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/bushido-bun/opponent/bushido-bun-a.png');
                            }
                        }
                    }
                }
            }
            &.opponent {
                .aspect-ratio--object {
                    animation: flashBushidoBun 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                    .fx {
                        &.bushido-bun {
                            transform: translate3d(-50%, -50%, 0) scale(5) rotate(180deg);
                            width: 100%;
                            height: 100%;
                            background-image: none;
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashBushidoBun 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.bushido-bun {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/bushido-bun/opponent/bushido-bun-a.png');
                            }
                        }
                    }
                }
            }

        }

    }
}

// Animations

@keyframes flashBushidoBun {
    0% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(255, 0, 0));
    }

    50% {
        filter: brightness(300%) drop-shadow(0px 0px 1rem rgb(255, 0, 0));
    }

    100% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(255, 0, 0));
    }
}
