.panel-gladiator {
    position: fixed;
    min-width: 375px;
    max-width: 420px;
    z-index: 9;
    transition: all 250ms ease;
    border-bottom: 2px solid #ffffff00;
    backdrop-filter: blur(1.25rem);
    background-color: #2e1d1170;
    box-shadow: rgba(0, 0, 0, 0.25) 0 5px 5px;

    ion-header, ion-footer {
        background: transparent;
        border-top: 0px;
        border-bottom: 0px;
    }
    ion-toolbar {
        --background: transparent;
    }
    ion-content {
        --background: transparent;
        min-height: 100vh;
        --padding-top: calc(env(safe-area-inset-top) + 1.25rem);
        & > .row:last-child {
            margin-bottom: 250px;
        }
    }

    &.top-left {
        top: 62px;
        left: 0px;
        transform: translate3d(-100%, 0%, 0px);
        &.open {
            transform: translate3d(0%, 0%, 0px);
        }
    }
    &.top-right {
        top: 62px;
        right: 0px;
        transform: translate3d(100%, 0%, 0px);
        &.open {
            transform: translate3d(0%, 0%, 0px);
        }
    }

}