.gladiator-page {
    &.get-ready {

        ion-searchbar {
            border: 2px solid #c3b37590;
            border-radius: 0.5rem;
            background: rgba(145, 88, 34, 0.5);
            padding-top: 2px;
            padding-bottom: 2px;
            min-height: auto;
            .searchbar-input-container {
                min-height: auto;
                input {
                    font-family: 'Garamond', serif;
                }
            }
        }

        ion-segment {
            margin-top: 0.5rem;
            overflow: visible;
            contain: none;
            border-radius: 0.5rem;
            background: transparent;
            padding-top: -1rem;    
            ion-segment-button {
                font-size: 14px;
                --border-radius: 0.5rem !important;
                --color: $white;
            }
            ion-segment-button:not(.segment-button-checked) {
                --color: #FFF;
            }
            ion-segment-button.segment-button-checked {
                --indicator-box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
            }
        }
        
        ion-segment-button::part(indicator-background) {
            background: #915822 !important;    
        }

        #tsparticles {
            width: 100%;
            height: 100%;
            display: block;
            z-index: 20;
            position: absolute;
        }
        .page-content {
            @media screen and (min-width: 64em) {
                padding-top: calc(env(safe-area-inset-top) + 100px);
            }
            z-index: 22;
            .scrollable-page-content {
                // height: calc(100vh - 258px);
                @media screen and (min-width: 64em) {
                    height: calc(100vh - 100px);
                }
                .player-card-container {
                    transition: all 0.3s ease;
                }

                .gladiator-cards {

                    .player-card-container {
                        .player-card {
                            transition: all 0.3s ease;
                            &.selected {
                                animation: blink 1s infinite;
                            }
                            &:hover {
                                cursor: pointer !important;
                            }
                        }
                        .select-text {
                            padding-top: 4px !important;
                        }
                        .selected-overlay {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            padding: 1rem;
                            border-radius: 6px;
                            background-color: rgba(0, 0, 0, 0.75);
                            z-index: 1;
                            transform: translate(-50%, -50%);
                            line-height: 1.1;
                            height: 50px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 1px solid var(--gladiator-gold);
                            opacity: 0;
                            p {
                                font-size: 1.25rem;
                                color: #FFF;
                                margin: 0px;
                                margin-top: 0.65rem;
                            }
                            // fade in animation
                            animation: fadeIn 0.5s forwards;
                            // delay the animation
                            animation-delay: 0.5s;
                            @keyframes fadeIn {
                                0% {
                                    opacity: 0;
                                }
                                100% {
                                    opacity: 1;
                                }
                            }            
                        }
                    }

                }

                .gladiator-preview {

                    .card-preview-container {
                        height: calc(100% - 80px);
                        & > div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            .player-card, .placeholder {
                                transition: all 0.3s;
                                max-width: 500px;
                                @media screen and (max-height: 990px) {
                                    max-width: 400px;
                                }
                                @media screen and (max-height: 860px) {
                                    max-width: 300px;
                                }
                            }
                        }




                    }

                }

                .gladiator-equip {

                    height: calc(100vh - 100px);
                    overflow: auto;
                    padding-bottom: 250px;
                    position: relative;
                    width: 25%;

                    & > .equipment {

                        overflow: auto;
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        height: 40%;
                        width: 100%;
                        // border: 1px solid red;

                        .player-card {
                            width: 200px;
                            margin: 0rem 1rem;
                            padding: 0rem 1rem;
                            transition: all 0.3s;
                            &.selected {
                                animation: blink 1s infinite;
                            }
                        }
                        .already-attached {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 50%;
                            left: 50%;
                            padding: 1rem;
                            border-radius: 6px;
                            background-color: rgba(0, 0, 0, 0.75);
                            z-index: 1;
                            transform: translate3d(-50%, -50%, 0);
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .shop-item {
                            width: calc(25% - 0.5rem);
                            margin: 0.25rem;
                            border: var(--gladiator-gold-dark) 1px solid;
                            border-radius: 6px;
                            padding: 0.25rem;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            transition: all 0.3s;
                            background-color: rgba(50, 30, 12, 0.5);
                            backdrop-filter: blur(10px);
                            min-width: 75px;
                            min-height: 75px;
                            transform: scale(0.9);
                            &:hover {
                                transform: scale(.95);
                                cursor: pointer;
                                filter: drop-shadow(0px 0.25rem 0.5rem var(--gladiator-gold-dark));
                            }
                            &.selected, &.attached-to-current {
                                transform: scale(1);
                                animation: pulseEquippedItem 1s infinite;
                            }
                            &.attached {
                                transform: scale(0.9);
                                filter: brightness(0.5);
                                cursor: not-allowed;
                            }
                            @keyframes pulseEquippedItem {
                                0% {
                                    border-color: white;
                                }
                                50% {
                                    border-color: var(--gladiator-gold);
                                }
                                100% {
                                    border-color: white;
                                }
                            }
                        }
                    }

                    .team {

                        position: absolute;
                        bottom: 0px;
                        right: 0px;
                        height: 60%;
                        width: 100%;
                        // border: 1px solid red;

                        .team-container {
                            position: relative;
                            width: 100%;
                            height: 300px;
                            perspective: 1000px;
                            transform-style: preserve-3d;
                            transform: scale(0.825);
                            // &:hover {
                                // .team-member {
                                //     cursor: pointer;
                                //     filter: drop-shadow(0px 0px 0.20rem #FFF);
                                // }
                            // }
                            .team-member {
                                position: absolute;
                                width: 175px;
                                top: 50%;
                                left: 50%;
                                transform: translate3d(-50%, 50%, 0);
                                transition: all 0.3s;
                                .player-card {
                                    transition: all 0.3s;
                                }
                                // &:hover {
                                    // .player-card {
                                        // filter: drop-shadow(0px 0px 0.5rem #FFF);
                                    // }
                                // }
                                &.selected {
                                    z-index: 1;
                                }
                            }
                            &.team-count-1 {
                                .team-member {
                                    transform-origin: bottom center;
                                    transform: translate3d(-50%, -50%, 0) rotate(0deg);
                                    &:hover {
                                        transform: translate3d(-50%, -50%, 0) rotate(0deg) scale(1.1);
                                    }
                                }
                            }
                            &.team-count-2 {
                                .team-member {
                                    &:nth-child(1) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-65%, -50%, 0) rotate(-15deg);
                                        &:hover {
                                            transform: translate3d(-65%, -50%, 0) rotate(-15deg) scale(1.1);
                                        }
                                    }
                                    &:nth-child(2) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-45%, -50%, 0) rotate(15deg);
                                        &:hover {
                                            transform: translate3d(-45%, -50%, 0) rotate(15deg) scale(1.1);
                                        }
                                    }
                                }
                            }
                            &.team-count-3 {
                                .team-member {
                                    &:nth-child(1) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-75%, -50%, 0) rotate(-15deg);
                                        &:hover {
                                            transform: translate3d(-75%, -50%, 0) rotate(-15deg) scale(1.1);
                                        }
                                    }
                                    &:nth-child(2) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-50%, -50%, 0) rotate(0deg);
                                        &:hover {
                                            transform: translate3d(-50%, -50%, 0) rotate(0deg) scale(1.1);
                                        }
                                    }
                                    &:nth-child(3) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-25%, -50%, 0) rotate(15deg);
                                        &:hover {
                                            transform: translate3d(-25%, -50%, 0) rotate(15deg) scale(1.1);
                                        }
                                    }
                                }
                            }
                            &.team-count-4 {
                                .team-member {
                                    &:nth-child(1) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-50%, -50%, 0) rotate(-15deg);
                                    }
                                    &:nth-child(2) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-50%, -50%, 0) rotate(-5deg);
                                    }
                                    &:nth-child(3) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-50%, -50%, 0) rotate(5deg);
                                    }
                                    &:nth-child(4) {
                                        transform-origin: bottom center;
                                        transform: translate3d(-50%, -50%, 0) rotate(15deg);
                                    }
                                }
                            }
                
                            .player-card {
                                &.selected {
                                    animation: blink 1s infinite;
                                }
                            }
                        }

                    }

                    .info-cta {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-bottom: 2rem;
                    }

                    .power-container-get-ready {
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        transform: translateY(-25%);
                    }

                }

                .choose-wisely {
                    svg {
                        width: 500px;
                    }
                    h3 {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%, -50%, 0);
                    }
                }

            }
        }

        .button-frame {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        #ready-controls-container {
            position: absolute;
            bottom: 1rem;
            left: 0px;
            z-index: 7;
            width: 50%;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
        }

        #team-controls {

        }

        #ready-controls {
            text-align: center;
            position: fixed;
            bottom: 1rem;
            width: 25%;
            z-index: 9999;
            right: 0px;

            #inner, #right-arrow, #left-arrow {
                transition: all 350ms ease;
            }
            &.remove {
                #right-arrow {
                    opacity: 0;
                }
                &:hover {
                    #left-arrow {
                        fill: #F0E497;
                        opacity: 1;
                        transform: translateX(4px);
                    }
                }                    
            }
            &.add {
                #left-arrow {
                    opacity: 0;
                }                    
                &:hover {
                    #right-arrow {
                        fill: #F0E497;
                        opacity: 1;
                        transform: translateX(-4px);
                    }
                }
            }
            &.ready {
                #left-arrow {
                    opacity: 0;
                }
                #right-arrow {
                    opacity: 0;
                }
                #inner {
                    fill: #725A2B;
                }
            }
        }

        ion-footer {
            ion-button {
                padding-top: 0rem;
                padding-bottom: 0rem;
                min-height: none;
                --padding-start: 0.5rem;
                --padding-end: 0.5rem;
                margin-inline-start: 0.5rem;
                margin-inline-end: 0.5rem;
                &.has-badge {
                    padding-right: 0.5rem;
                }
                ion-badge {
                    position: absolute;
                    top: -6px;
                    right: -8px;
                    font-size: 1rem;
                }
            }
        }

        .begin-message {
            text-align: center;
            @media screen and (min-width: 64em) {
                text-align: left;
            }
        }

    }

    .gladiator-bottom-border {
        height: 1rem;
    }

}

@keyframes blink {
    0% {
        filter: drop-shadow(0px 0px 0.15rem #FFF);
    }
    50% {
        filter: drop-shadow(0px 0px 0.15rem var(--gladiator-gold));
    }
    100% {
        filter: drop-shadow(0px 0px 0.15rem #FFF);
    }
}