// Flip Card Reveal Styles
// This is the base of all cards
.flip-card {

    background-color: transparent;
    width: 100%;
    perspective: 1000px;
    min-width: 110px;

    .flip-card-inner {

        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;

        .flip-card-front, .flip-card-back {

            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            .front-card-container, .back-card-container {

                width: 100%;
                height: 100%;

                .card-contents {

                    width: 100%;
                    height: 100%;
    
                    .nft {

                        width: 100%;
                        margin: 0 auto;
                        height: 100%;
                        position: relative;
                        transform-style: preserve-3d;
                        transform: perspective(1000px);
                    
                        .art-container-bg {

                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, -50px);
                            z-index: 1;

                            img {

                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                object-position: top center;

                            }

                        }
                    
                        .art-container {
                            width: 82%;
                            height: 70%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, -25px) scale(1);
                            z-index: 3;
                            object-fit: cover;
                        }
                    
                        .card-frame {

                            height: 100%;
                            width: 100%;
                            transform: translate3d(0%, 0%, 6px);
                            z-index: 8;

                            img {

                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                transition: all 500ms ease;
                                filter: drop-shadow(0px 0.5rem 4px #0000006c);

                            }

                            &.equip-effect {
                                img {
                                    transition: all 100ms ease;
                                    filter: brightness(12) drop-shadow(0px 0px 25rem #FFF) blur(3px);
                                }
                            }
                            &.dark {
                                img {
                                    filter: brightness(0.5) saturate(0);
                                }
                            }

                        }
                    
                        .card-text-behind-frame {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, 4px);
                            z-index: 6;
                            .info {
                                transition: all 250ms ease !important;
                                position: absolute;
                                bottom: 2.75rem;
                                left: 50%;
                                transform: translate3d(-50%, 0%, 25px);
                                background-color: rgba(0, 0, 0, 0.75);
                                width: 82%;
                                height: calc(100% - 5.5rem);
                                padding: 1rem 1.5rem;
                                font-size: 1rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                flex-wrap: wrap;
                                align-content: center;
                                text-align: center;
                                animation: fadeIn 500ms forwards;
                                flex-direction: column;
                                .info-ab-name {
                                    padding: 0px;
                                    margin-top: 0.5rem;
                                    display: inline-block;
                                    margin-bottom: 0.5rem;
                                    transform: translateX(-.25rem);
                                }
                                .desc {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    .inner-label {
                                        .value {
                                            display: inline-block;
                                            position: relative;
                                            font-size: 2rem;
                                        }
                                    }
                                    &.power {
                                        margin: 0px;
                                        line-height: 1.1;
                                        filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
                                        font-size: 1.5rem;
                                        .dmg {
                                            margin-left: 0.25rem;
                                            display: inline-block;
                                            transform: translateY(-0.14rem);
                                        }
                                        .value {
                                            transform: translateY(0.3rem);
                                        }
                                    }
                                    &.energy {
                                        margin: 0px;
                                        img {
                                            margin-left: 0.4rem;
                                            width: 1rem;
                                            height: auto;
                                            transform: translateY(-0.4rem);
                                        }
                                        .value {
                                            transform: translateY(0.3rem);
                                        }
                                    }
                                }
                            }
                        }

                        .card-text {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, 20px);
                            z-index: 6;

                            .name {
                                text-shadow: 
                                1px 1px 2px black, 
                                0 0 1em black, 
                                0 0 1em black, 
                                0 0 1em black, 
                                0 0 0.2em black;
                                position: absolute;
                                bottom: 4.5rem;
                                left: 50%;
                                transform: translate3d(-50%, 0%, 25px);
                                animation: fadeIn 500ms forwards;
                                @media screen and (max-width: 768px) {
                                    bottom: 25% !important;
                                    font-size: 0.9rem;
                                }
                                @media screen and (max-width: 430px) {
                                    bottom: 23% !important;
                                    font-size: 0.8rem;
                                }
                            }

                            .info {
                                position: absolute;
                                bottom: 4.5rem;
                                left: 50%;
                                transform: translate3d(-50%, 0%, 25px);
                                font-size: 0.75rem;
                                width: 70%;
                                padding: 0.25rem 0.75rem;
                            }

                            .stats {

                                position: absolute;
                                top: 50%;
                                left: 50%;
                                width: 100%;
                                height: calc(100% - 16%);
                                transform: translate3d(-50%, -50%, 0);
                                text-shadow: 
                                    1px 1px 2px black, 
                                    0 0 1em black, 
                                    0 0 1em black, 
                                    0 0 1em rgb(200, 129, 129), 
                                    0 0 0.2em black;

                                // Abilities and Stat Indicators
                                .ab1, .ab2, .defense, .health {
                                    position: absolute;
                                }

                                // Abilities
                                .ab1, .ab2 {
                                    top: 3%;
                                    text-align: center;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: rgb(0 0 0 / 50%);
                                    border-radius: 50%;
                                    border: 2px solid #FFF;
                                    filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
                                    $wh: 38px;
                                    $pd: 0.15rem;
                                    width: $wh;
                                    height: $wh;
                                    img {
                                        object-fit: contain;
                                        filter: drop-shadow(0px 0px 2px black);
                                        width: calc(100% - $pd);
                                        height: calc(100% - $pd);
                                    }
                                    @media screen and (min-width: 768px) {
                                        $wh: 55px;
                                        $pd: 0.75rem;
                                        width: $wh;
                                        height: $wh;
                                        img {
                                            width: calc(100% - $pd);
                                            height: calc(100% - $pd);
                                        }
                                    }
                                    &.selected-true {
                                        animation: glowSelectedBright 1s infinite;
                                    }
                                    &:disabled {
                                        background-color: rgba(0, 0, 0, 0.75);
                                        border: 2px solid rgba(255, 255, 255, 0.25);
                                        .ability-icon {
                                            filter: brightness(0.25);
                                        }
                                    }
                                }
                                .ab1 {
                                    transform-origin: top left;
                                    left: 5%;
                                }
                                .ab2 {
                                    transform-origin: top right;
                                    right: 5%;
                                }

                                // Defense and Health
                                .defense, .health {
                                    filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
                                    background-color: rgb(0 0 0 / 50%);
                                    border-radius: 50%;
                                    width: 30px;
                                    height: 30px;
                                    bottom: 3%;
                                    font-size: 1rem;
                                    & > span {
                                        position: relative;
                                        white-space: nowrap;
                                        letter-spacing: -1px;
                                        font-weight: bold;
                                        transform: translateX(-1px);
                                    }
                                    @media screen and (min-width: 768px) {
                                        transform: scale(1.5);
                                        width: 40px;
                                        height: 40px;
                                        font-size: 1.35rem;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                    }
                                }
                                .defense {
                                    transform-origin: bottom left;
                                    left: 5%;
                                    border: 2px solid #F2D06A;
                                }
                                .health {
                                    transform-origin: bottom right;
                                    right: 5%;
                                    border: 2px solid rgb(194 0 0);
                                }

                                .ab1-info {
                                    position: absolute;
                                    top: 0rem;
                                    left: 0rem;
                                    text-align: left;
                                    width: 200px;
                                    transform: translate3d(0, -100%, 0);
                                }

                                .ab2-info {
                                    position: absolute;
                                    top: 0rem;
                                    left: 0rem;
                                    text-align: left;
                                    width: 200px;
                                    transform: translate3d(0, -100%, 0);
                                }

                                // Stat Change Difference

                                .stat-change-difference {
                                    position: absolute;
                                    width: 75px;
                                    height: 75px;
                                    text-shadow: 
                                        1px 1px 2px black, 
                                        0 0 1em black, 
                                        0 0 1em black, 
                                        0 0 1em black, 
                                        0 0 0.2em black;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    line-height: 1;
                                    &.defense-calc {
                                        bottom: 10%;
                                        left: 0px;
                                        right: auto;
                                        transform: translateX(-15%);
                                    }
                                    &.health-calc {
                                        bottom: 10%;
                                        left: auto;
                                        right: 0px;
                                        transform: translateX(15%); 
                                    }
                                    @media screen and (min-width: 768px) {
                                        &.defense-calc, &.health-calc {
                                            bottom: 20%;
                                        }
                                    }
                                }

                            }

                            .owned-overlay {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                width: 100%;
                                height: 100%;
                                text-shadow: 
                                    1px 1px 2px black, 
                                    0 0 1em black, 
                                    0 0 1em black, 
                                    0 0 1em rgb(200, 129, 129), 
                                    0 0 0.2em black; 
                                display: flex;  
                                justify-content: center;
                                align-items: center;
                            }

                        }
                    
                        .wearable-image {
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, 19px);
                            z-index: 6;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                transition: all 500ms ease;
                                // filter: drop-shadow(0px 0px 1.5rem #000);
                            }
                            &.equip-effect {
                                img {
                                    transition: all 100ms ease;
                                    filter: brightness(12) drop-shadow(0px 0px 25rem #FFF) blur(3px);
                                }
                            }
                        }

                    }
                
                }

            }

        }
    
        .flip-card-front {
            transition: all 1s ease;
        }
        .flip-card-back {

            opacity: 0;
            color: white;
            transform: rotateY(180deg);
            transform-style: preserve-3d;
            transition: all 1s ease;
            transform-origin: center;
        
            .back-card-container {

                width: 100%;
                height: 100%;

                .card-contents {

                    width: 100%;
                    height: 100%;

                    .nft {
                        .card-frame-text {
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                        .art-container {
                            transform: translate3d(-50%, calc(-50% - 1px), 0px);
                            mix-blend-mode: hard-light;
                            object-fit: cover;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }

        }
    
    }

    &:not(.flipped) {
        .flip-card-inner {
            transform: rotateY(180deg);
        }
        .flip-card-front {
            opacity: 0;
        }
        .flip-card-back {
            opacity: 1;
        }
    }

    &.shop-item {
        .art-container {
            width: 80% !important;
            height: 90% !important;
            object-fit: cover;
            // transform: translate3d(-50%, calc(-50% - 0.5rem), -5px) scale(1) !important;
        }
    }

    // &.os-Mac {
    //     .stats .defense, .stats .health {
    //             line-height: 51px;
    //     }
    // }

}

// Vanilla Tilt Customization
.js-tilt-glare {
    position: relative;
    transform-style: preserve-3d;
    transform: perspective(1000px);
}

.js-tilt-glare {
    z-index: 4;
    transform: perspective(1000px) translate3d(0px, 0px, 5px) scale(0.8);
    // mix-blend-mode: screen;
}

// Card styles for game experience
.player-cards {

    .player-card {
        max-height: 275px;
        max-width: 225px;

        &.shop-item {
            max-width: 100%;
        }

        position: relative;
        z-index: 1;
        transition: all 350ms ease;
        transform: translateY(0rem) scale(1);

        &.player.my-turn-true:not(.moving) {
            &:hover {
                transition: all 350ms ease;
                transform-origin: bottom center;
                transform: translateY(-1rem) scale(1.5);
                z-index: 10;
            }
        }

        &.opponent {
            cursor: pointer;
            &.hover {
                transform-origin: top center;
                transform: translateY(-1rem) scale(1.15);
                z-index: 10;
            }
        }

        &.selected {
            animation: glowSelectedBright 2s infinite;
        }

        &.resurrected {
            animation: resurrected 2s infinite;
        }

        &[data-battle-fx] {
            pointer-events: none !important;
        }

        &.evade {
            opacity: 0.1 !important;
            transform: translate3d(0, 0, 0) scale(0.75);
        }

        // Death
        &.card-dead {
            transition: all 500ms ease;
            overflow: visible;
            mix-blend-mode: overlay;
            z-index: 9999999;
            .aspect-ratio--object {
                animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 450ms;

                .fx {
                    transform: translateX(2rem);

                    &.death {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate3d(-50%, -50%, 0) scale(2) rotate(90deg);
                        width: 100%;
                        height: 100%;
                        background-image: url('../../images/death.png');
                        z-index: 999999;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        // sepia color filter with a hint of brightness and contrast
                        filter: sepia(1) brightness(1.5) contrast(1.5);
                    }
                }
            }
            &.dissapear {
                transform: translate3d(0, 0, 0) scale(0);
            }
            &.shrinked {
                width: 0px;
                height: 0px;
            }
            &.removed {
                display: none;
            }
        }

    }

}