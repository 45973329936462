// Player Cards FX

.player-cards {
    .player-card {

        &[data-battle-fx="spork-whale"] {
            .aspect-ratio--object {
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
                animation-delay: 150ms;

                .fx {
                    transform: translateX(2rem);

                    &.spork-whale {
                        position: absolute;
                        border: 0px solid red;
                        left: 50%;
                        top: 50%;
                        width: 100%;
                        height: 100%;
                        img {
                            width: 100%;
                            height: 100%;    
                            z-index: 999999;
                            object-fit: contain;
                        }
                    }
                }
                .flip-card-inner {
                    transition: all 500ms ease;
                    animation: flashSporkWhale 2.5s linear forwards, shake .5s cubic-bezier(.36, .07, .19, .97) infinite;
                }
            }
            &.player {
                .fx.spork-whale {
                    animation: quickSpinCounterClockwisePlayer 1s forwards;
                }
            }
            &.opponent {
                .fx.spork-whale {
                    animation: quickSpinCounterClockwiseOpponent 1s forwards;
                }
            }
        }

    }
}

// Animations

@keyframes flashSporkWhale {
    0% {
        -webkit-filter: drop-shadow(0px 0px 0.25rem #ff00f7) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(1);
        filter: drop-shadow(0px 0px 0.25rem #ff00f7) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(1);
    }

    50% {
        -webkit-filter: drop-shadow(0px 0px 1rem #ffb300) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(2.5);
        filter: drop-shadow(0px 0px 1rem #ffb300) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(2.5);
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 0.25rem #ff00f7) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(1);
        filter: drop-shadow(0px 0px 0.25rem #ff00f7) grayscale(100%) sepia(1) hue-rotate(120deg) saturate(1000%) brightness(1);
    }
}
@keyframes quickSpinCounterClockwisePlayer {
    0% {
        opacity: 0;
        transform: translate3d(-25%, -100%, 0) scale(0.25) rotate(180deg);
        -webkit-filter: drop-shadow(0px 0px 3rem #00ff37);
        filter: drop-shadow(0px 0px 3rem #00ff37);
    }

    100% {
        opacity: 1;
        transform: translate3d(-25%, -100%, 0) scale(2) rotate(-5deg);
        -webkit-filter: drop-shadow(0px 0px 3rem #00fff7);
        filter: drop-shadow(0px 0px 3rem #00fff7);
    }
}

@keyframes quickSpinCounterClockwiseOpponent {
    0% {
        opacity: 0;
        transform: translate3d(-5%, -10%, 0) scale(0.25) rotate(180deg);
        -webkit-filter: drop-shadow(0px 0px 3rem #00ff37);
        filter: drop-shadow(0px 0px 3rem #00ff37);
    }

    100% {
        opacity: 1;
        transform: translate3d(-5%, -10%, 0) scale(2) rotate(-5deg);
        -webkit-filter: drop-shadow(0px 0px 3rem #00ff37);
        filter: drop-shadow(0px 0px 3rem #00ff37);
    }
}
