body, * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-wrap: pretty;
}

ion-header {
    backdrop-filter: blur(1.25rem);
    &.gladiator-header {
        padding-top: env(safe-area-inset-top);
    }
    .logo-container {
        width: 100vw;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 1;
        .logo {
            $logoSize: 70px;
            position: absolute;
            width: $logoSize;
            height: $logoSize;
            object-fit: contain;
            object-position: center;
        }
    }
}

ion-content {
    &::-webkit-scrollbar {
        width: 10px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    &::-webkit-scrollbar-thumb {
        background: #584931; 
        border-radius: 0px;
    }
    
    &::-webkit-scrollbar-thumb:hover {
        background:transparent; 
    }
    scrollbar-color: #584931 transparent;
    scrollbar-base-color: transparent;
    scrollbar-face-color: transparent;
    scrollbar-3dlight-color: transparent;
    scrollbar-highlight-color: transparent;
    scrollbar-track-color: transparent;
    scrollbar-arrow-color: transparent;
    scrollbar-shadow-color: transparent;
    scrollbar-dark-shadow-color: transparent;
    &.transparent {
        --background: transparent;
    }
}

ion-footer {
    background-color: transparent;
    backdrop-filter: blur(1.25rem);
}

.alert-wrapper.sc-ion-alert-ios,
.loading-wrapper.sc-ion-loading-ios {
    border: 2px solid #9d905e !important;
    box-shadow: 0px 3px 2rem rgba(46,29,17, 1), 0px 3px 2rem rgba(46,29,17, 1) !important;
    background: hsl(29deg 44.79% 15.73%) !important;
}

.alert-button-group-vertical.sc-ion-alert-ios .alert-button.sc-ion-alert-ios,
.alert-button-group.sc-ion-alert-ios,
.alert-button.sc-ion-alert-ios,
.loading-wrapper.sc-ion-loading-ios .loading-spinner {
    color: white !important;
}

// Scrolling content subtle border
.gladiator-top-border {
    content: "";
    position: sticky;
    top: 0px;
    left: 50%;
    width: calc(100vw);
    height: 3rem;
    transform: translate3d(0%, 0px, 0px);
    background: linear-gradient(180deg, rgba(46,29,17,1) 0%, rgba(46,29,17,0) 90%);
    z-index: 6;
    border-top: 1px solid var(--gladiator-gold-dark);
    // animation: fadeIn 500ms forwards;
}

.gladiator-bottom-border {
    content: "";
    position: fixed;
    bottom: 62px;
    left: 50%;
    width: calc(100vw);
    height: 3rem;
    transform: translate3d(calc(-50% - 10px), 0px, 0px);
    background: linear-gradient(0deg, rgba(46,29,17,1) 0%, rgba(46,29,17,0) 90%);
    z-index: 6;
    border-bottom: 1px solid var(--gladiator-gold-dark);
    // animation: fadeIn 500ms forwards;
}

.page-content {
    position: relative;
    padding-top: calc(env(safe-area-inset-top) + 75px);
    .scrollable-page-content {
        max-width: 100%;
        overflow: hidden;
        overflow-y: auto;
        @media screen and (min-width: 64em) {
            height: calc(100vh - 198px);
        }
    }
}

.row {
    &.full-width {
        max-width: 100%;
    }
}

.text-drop-shadow {
    filter: drop-shadow(0px 3px 6px rgba(46,29,17, 0.85)) drop-shadow(0px 3px 6px rgba(46,29,17, 0.85));
    ;
}

.text-drop-shadow-dark {
    filter: drop-shadow(0px 3px 6px rgba(46,29,17, 1)) drop-shadow(0px 3px 6px rgba(46,29,17, 1)) drop-shadow(0px 5px 6px rgba(46,29,17, 1));
}

.sticky {
    position: sticky;
    top: 0px;
    z-index: 5;
}

// Theme Change Overlay
#theme-change-overlay {
    $transitionTime: 1.5s;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 105vw;
    height: 105vw;
    transform: translate3d(-50%, -50%, 0);
    z-index: 15;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    animation: fade-in-overlay $transitionTime forwards;
    &.fade-out {
        animation: fade-out-overlay $transitionTime forwards !important;
    }
}

@keyframes fade-in-overlay {
    0% {
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
        -webkit-filter: blur(0px);
    }
    100% {
        background-color: rgba(0, 0, 0, 1);
        backdrop-filter: blur(32px);
        -webkit-filter: blur(32px);
    }
}

@keyframes fade-out-overlay {
    0% {
        opacity: 1;
        background-color: rgba(0, 0, 0, 1);
        backdrop-filter: blur(32px);
        -webkit-filter: blur(32px);
    }
    100% {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0);
        backdrop-filter: blur(0px);
        -webkit-filter: blur(0px);
    }
}

// Button Text
.button-text {
    font-family: 'PT Serif' !important;
    font-weight: 600;
    color: white;
    text-shadow: 0px 3px 6px rgba(46,29,17, 0.85);
    line-height: normal;
}

.backdrop-blur, .blur-backdrop {
    -webkit-backdrop-filter: blur(1.25rem);
    backdrop-filter: blur(1.25rem);
    display: inline-block;
}

.container-with-blur {
    border: 2px solid rgba(255, 255, 255, 0.15);
    background-color: #58493100;
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
    min-height: 200px;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: calc(100% - 2rem);
}

// Waiting on Opponent
.waiting-on-opponent-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

// Login / Signup with Email
#email-form {

    background: var(--gladiator-gold-very-dark) !important;
    border-color: var(--gladiator-gold-dark) !important;
    border: 1px solid;
    border-radius: 8px;
  
    .chevron-form-submit {
        --box-shadow: none;
        border-color: var(--gladiator-gold-dark) !important;
        border: 1px solid;
        border-radius: 8px;
        background: var(--ion-color-light) !important;
        background: transparent;
        box-shadow: none;
        position: absolute;
        top: 50%;
        right: 8px;
        z-index: 10;
        transform: translateY(-50%);
        margin: 0px;
        transition: all 0.3s ease;
        &:hover, &:active, &:focus {
            background : var(--gladiator-gold-dark) !important;
        }
    }

}

// Gladiator Input Wrapper (Ion Input Customizations)
.gladiator-input-wrapper {

    background: var(--gladiator-gold-very-dark) !important;
    border-color: var(--gladiator-gold-dark) !important;
    border: 1px solid;
    border-radius: 8px;

    // Gladiator Input (Ion Input Customizations)
    .gladiator-input {
        --border-width: 0px;
        --color: white !important;
        --padding-start: 0.75rem !important;
        --padding-end: 0.25rem !important;
        --padding-top: 0.25rem !important;
        --padding-bottom: 0.25rem !important;
        --border-radius: 6px !important;
        caret-color: white !important;
        &.padding-end {
            --padding-end: 65px !important;
        }
        &.otp {
            --padding-start: 0.25rem !important;
            --padding-end: 0.25rem !important;
            --padding-top: 0.25rem !important;
            --padding-bottom: 0.25rem !important;    
        }
    }

}

// Tiny Chain Logos for Each Collection
.chain-logo {
    .chain-icon {
        color: white;
        ion-icon {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
        }
    }
    .chain-name {
        font-weight: 600;
        color: white;
    }
}