$spacesStorage: 'https://brokenreality-gladiator.nyc3.cdn.digitaloceanspaces.com/collections/cosmicmuffins/fx';

// Player Cards FX

.player-cards {
    .player-card {

        &[data-battle-fx="cosmic-laser"] {

            &.player {
                .aspect-ratio--object {
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .fx {
                        &.cosmic-laser {
                            position: absolute;
                            border: 0px solid red;
                            left: 50%;
                            top: 50%;
                            z-index: 999999;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            animation: laser, cursorEffect 0.25s infinite linear;
                        }
                    }
                }
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashLaser 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                        .fx {
                            &.cosmic-laser {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/cosmic-laser/impact.png');
                            }
                        }
                    }
                }
            }

            &.opponent {
                .aspect-ratio--object {
                    animation: flashLaser 0.5s linear, shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                    .fx {
                        &.cosmic-laser {
                            position: absolute;
                            border: 0px solid red;
                            left: 50%;
                            top: 50%;
                            z-index: 999999;
                            background-size: contain;
                            background-position: center;
                            background-repeat: no-repeat;
                            animation: laser, dustEffect 0.25s infinite linear;
                        }
                    }
                }
                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        .fx {
                            &.cosmic-laser {
                                transform: translate3d(-50%, -50%, 0) scale(5) rotate(90deg);
                                width: 100%;
                                height: 100%;
                                background-image: url('#{$spacesStorage}/cosmic-laser/impact.png');
                            }
                        }
                    }
                }
            }


            .laser-beam {
                position: absolute;
                width: 18%;
                height: 0%;
                background-size: 200% 200%;
                border-radius: 100px;
                // z-index: 99999;
                filter: blur(3px) saturate(2);
            
                &.attack {
                    background: linear-gradient(0deg, rgba(99, 195, 231, 0.6), purple, pink);
                    animation: laser 1s infinite, gradientUp 1s linear infinite;
            
                    &.animate__fadeOut {
                        display: none !important;
                    }
                }
            
                &.incoming {
                    background: linear-gradient(0deg, pink, purple, rgba(99, 195, 231, 0.6));
                    animation: laser 1s infinite, gradientDown 1s linear infinite;
            
                    &.animate__fadeOut {
                        display: none !important;
                    }
                }
            }

        }

    }
}

// Animations

@keyframes flashLaser {
    0% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem red) hue-rotate(0deg);
    }

    50% {
        filter: brightness(300%) drop-shadow(0px 0px 1rem white) hue-rotate(180deg);
    }

    100% {
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem red) hue-rotate(360deg);
    }
}


@keyframes laser {
    0% {
        box-shadow: 0px 0px 20px 5px rgba(99, 195, 231, 1);
    }

    50% {
        box-shadow: 0px 0px 20px 25px rgb(255, 255, 255);
    }

    100% {
        box-shadow: 0px 0px 20px 5px pink;
    }
}