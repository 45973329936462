.gladiator-page {
    overflow: hidden;
    &.play {
        ion-content {
            --background: transparent;
            --overflow: hidden;
            // --offset-top: 0px !important;
        }

        &.my-turn-false {
            .player-elements .player-cards.top {
                z-index: 20;
            }
        }
        &.my-turn-true {
            .player-elements .player-cards.bottom {
                z-index: 20;
            }
        }
    }

    &.collection, &.collectible {
        .links {
            a {
                color: #FFF;
                transition: all 350ms ease;
                filter: drop-shadow(0px 0px 0rem #000);
                svg {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin: 0 0.5rem;
                }
                &:hover, &:active, &:focus {
                    color: var(--gladiator-gold);
                    text-decoration: none;
                    transform: scale(1.5);
                    filter: drop-shadow(0px 0px 0.5rem #000);
                }
            }
        }
    }

    &.collection {

        .collection-container {
            width: 100%;
            max-width: 1200px;
        }

        .player-card-container {
            display: inline-flex;
            justify-content: center;
            width: 50%;
            @media screen and (min-width: 768px) {
                width: 33%;
            }
            .player-card {
                max-width: 400px;
            }

            .name {
                font-size: 1.25rem;
                bottom: 8rem !important;
            }
        }
        .collection-options {
            max-width: 78.75rem;
            padding: 1rem;
            .collection-button-container {
                width: 100%;
                @media screen and (min-width: 786px) {
                    width: 50%;
                }
                @media screen and (min-width: 1024px) {
                    width: 33%;
                }
            }
        }
    }

    &.collectible {
        .player-card-container {
            width: 100%;
            display: inline-block;

            .name {
                font-size: 1.25rem;
                bottom: 8rem !important;
            }
        }
        .collectible-row {
            width: 100%;
            max-width: 1200px;
        }
    }

    &.get-ready {
        .gladiator-top-border {
            height: 1rem;
        }
        .page-content .scrollable-page-content {
            overflow-y: hidden;
        }
        .player-card-container {
            width: 50%;
            display: inline-block;
            padding: 0.25rem;
            .name {
                font-size: 1rem;
                bottom: 3rem !important;
            }
        }
        .characters {
            padding-left: 0px;
        }
        .equipment {
            padding-right: 0px;
            ion-segment {
                ion-label span {
                    white-space: nowrap;
                }
            }
        }
        .characters {
            position: relative;
            overflow: auto;
            height: calc(100vh - 202px);
            padding-bottom: 150px;
            &.pagination {
                height: calc(100vh - 240px);
            }
        }
    }

    &.leaderboard {
        .player-placement-row {
            display: flex;
            flex-wrap: wrap;

            /*
            Our divs are in 1st, 2nd, 3rd order, on desktop, it should display as
            second, first, and third, so we'll use flex order to change the order
            */
            @media screen and (min-width: 1024px) {
                .first-place {
                    order: 0;
                }

                .second-place {
                    order: -1;
                }

                .third-place {
                    order: 1;
                }
            }
            
        }

        .place-container {
            max-width: 350px;
            margin: 0 auto;

            .player-card-container {
                max-width: 300px;
                margin: 0 auto;
            }

            .placement-block {
                border: 2px solid rgba(255, 255, 255, 0.15);
                background-color: #58493100;
                backdrop-filter: blur(1rem);
                border-radius: 1rem;
                padding-top: 3rem;
                margin-top: -4rem;
                padding-left: 2rem;
                padding-right: 2rem;
                padding-bottom: 1rem;
                min-height: 275px;
                margin-left: 1rem;
                margin-right: 1rem;
                margin-bottom: 2rem;

                &.placement-1 {
                    min-height: 350px;
                }

                &.placement-2 {
                    min-height: 300px;
                }
            }

        }

        .player-placement-table {
            width: 100%;
            margin-left: 1rem;
            margin-right: 1rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 4rem;
            @media screen and (min-width: 1024px) {
                margin-left: 8vw;
                margin-right: 8vw;
            }
            .player-card-container {
                max-width: 100px;
                .card-text .name {
                    bottom: 1rem !important;
                }
            }
        }

    }

    &.shop {
        .player-card-container {
            width: 400px;
            display: inline-block;

            .name {
                font-size: 1.25rem;
                bottom: 8rem !important;
            }
        }

        .actions {
            position: relative;
        }

        .player-cards {
            width: 100%;

            .player-card {
                max-height: none;
            }
        }
    }

}

// Play

// ion-app {
//     &.play {
//         .ion-page {
//             --ion-background-color: transparent;
//             z-index: 3;
//         }
//     }
// }

.collection-button {
    // min-width: 350px;
    min-height: 580px;
    // max-width: 400px;
    max-height: 600px;
    width: 100%;
    border: 1px solid #584931;
    background-color: #271F15;
    filter: drop-shadow(0px 0px 10px var(--overlay-color)) inset;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: #58493100;
    backdrop-filter: blur(1rem);
    border-radius: 1rem;
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    --overflow: visible;
    margin: 1rem;
    .inner-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 100%;
        position: relative;
        width: 100%;
        padding-bottom: 25px;
        z-index: 2;
        .logo {
            height: 220px;
            width: 100%;
            margin-top: 50px;
            object-fit: contain;
        }
        .cards {
            position: relative;
            display: flex;
            margin-top: -2rem;
            transform-style: preserve-3d;
            transform: perspective(1000px);
            min-height: 250px;
            img {
                max-width: 225px;
                transition: all 300ms ease;
                filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
                &.left, &.right {
                    transform: translateZ(-20px) scale(0.75);
                }
                &.center {
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%) translateZ(20px) translateY(-1rem) scale(0.9);
                }
            }
            .count {
                position: absolute;
                left: 50%;
                bottom: 0px;
                z-index: 9;
                filter: drop-shadow(0px 0.5rem 0.25rem #0000005c);
                transform: translateY(-2rem) translateX(-50%) translateZ(40px);
                .number {
                    font-size: 1.5rem;
                }
            }
        }
        &:hover {
            z-index: 999;
            .cards {
                img {
                    &.left {
                        top: -1rem;
                        transform: translateX(0%) translateZ(-20px) rotate(-10deg) scale(.8);
                    }
                    &.right {
                        top: -1rem;
                        transform: translateX(0%) translateZ(-20px) rotate(10deg) scale(.8);
                    }
                    &.center {
                        transform: translateX(-50%) translateZ(30px) translateY(-1rem) scale(.95);
                    }
                }
            }
        }
    }
    &.theme-button {
        width: 100%;
        min-width: 370px;
        min-height: 250px;
        max-width: 100%;
        max-height: 600px;  
        .logo {
            margin-top: 0px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }  
        .cards {
            display: none;
        }
    }
}

.border-accent {
    position: absolute;
    top: 0px;
    left: 0px;
    border: 0.45rem solid white;
    mix-blend-mode: overlay;
    &.outer {
        top: 1.15rem;
        left: 1.15rem;
        width: calc(100% - 2.3rem);
        height: calc(100% - 2.3rem);
    }
    &.inner {
        top: 1.15rem;
        left: 1.15rem;
        width: calc(100% - 2.3rem);
        height: calc(100% - 2.3rem);
    }
}

ion-toast {
    text-align: center;
    font-family: 'PT Serif' !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    letter-spacing: 0.25px;
    font-size: 1rem;
    --box-shadow: 0 0 2rem 5px #6a64ff7a;
}