@font-face {
    font-family: 'Gladiator Arena';
    src: url('GladiatorArenaRegular.eot');
    src: url('GladiatorArenaRegular.eot?#iefix') format('embedded-opentype'),
        url('GladiatorArenaRegular.woff2') format('woff2'),
        url('GladiatorArenaRegular.woff') format('woff'),
        url('GladiatorArenaRegular.ttf') format('truetype'),
        url('GladiatorArenaRegular.svg#GladiatorArenaRegular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.gladiator-arena {
    font-family: 'Gladiator Arena';
}