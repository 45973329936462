// Full Screen Abilities

#center-stage {

    .fx {

        &.spork-surge {
            position: absolute;
            border: 0px solid red;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100vh;
            z-index: 999999;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
            
            img {
                min-width: 450px;
                max-height: 500px;
                object-fit: contain;
                object-position: center;
                position: absolute;
                top: 50%;
                left: 50%;
                @extend .quick-bounce-in;
            }

            &.incoming {
                transform: translate3d(-50%, -50%, 0) rotate(0deg);
            }
            &.cardAbility {
                transform: translate3d(-50%, -50%, 0) rotate(0deg);
            }
        }

    }

}

// Player Card FX

.player-cards {
    .player-card {

        &[data-battle-fx="spork-surge"] {

            .fx {
                &.spork-surge {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
            }


            &.player {
                &[data-battle-fx-mode="incoming"] {
                    .aspect-ratio--object {
                        animation: flashSpork 1s linear infinite;
                        transform: translate3d(0, 0, 0);
                        backface-visibility: hidden;
                        animation-delay: 450ms;

                        .flip-card-inner {
                            animation: shake 0.5s ease-in-out both infinite;
                            animation-delay: .75s;
                        }
                    }

                    .aspect-ratio--object {
                        .fx {
                            &.spork-surge {
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 300%;
                                height: 300%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }

            &.opponent {
                .aspect-ratio--object {
                    transform: translate3d(0, 0, 0);
                    backface-visibility: hidden;
                    animation-delay: 450ms;
                }

                &[data-battle-fx-mode="cardAbility"] {
                    .aspect-ratio--object {
                        animation: flashSpork 1s linear infinite;
                        .flip-card-inner {
                            animation: shake 0.5s ease-in-out both infinite;
                            animation-delay: .75s;
                        }
                        .fx {
                            &.spork-surge {
                                transform: translate3d(-50%, -50%, 0) scale(1) rotate(0deg);
                                width: 300%;
                                height: 300%;
                                img {
                                    width: 100%;
                                    height: 100%;    
                                    z-index: 999999;
                                    object-fit: contain;
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}

// Animations

:root {
    --lift-height: 100px;
    /* How high the card lifts */
    --drop-intensity: 10px;
    /* How far it overshoots on the drop */
    --bounce-intensity: 10px;
    /* Bounce effect after slam */
    --animation-duration: 1.75s;
    /* Adjust speed */
}

@keyframes liftAndDrop {
    0% {
        transform: translateY(0) scale(1);
    }

    20% {
        transform: translateY(calc(-1 * var(--lift-height))) scale(1);
        /* Lift */
    }

    40% {
        transform: translateY(var(--drop-intensity)) scale(0.95);
        /* Drop slightly below */
    }

    50% {
        transform: translateY(0) scale(0.95);
        /* Slam */
    }

    60% {
        transform: translateY(calc(-0.5 * var(--bounce-intensity))) scale(0.95);
        /* Small bounce */
    }

    80% {
        transform: translateY(calc(0.25 * var(--bounce-intensity))) scale(1);
        /* Settle bounce */
    }

    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes flashSpork {
    0% {
        -webkit-filter: brightness(300%) drop-shadow(0px 0px 0.25rem rgb(0, 208, 255));
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(0, 208, 255));
    }

    50% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 1rem rgb(0, 208, 255));
        filter: brightness(300%) drop-shadow(0px 0px 1rem rgb(0, 208, 255));
    }

    100% {
        -webkit-filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(0, 208, 255));
        filter: brightness(100%) drop-shadow(0px 0px 0.25rem rgb(0, 208, 255));
    }
}

@keyframes sporkGlow {
    0% {
        -webkit-filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(2.2) brightness(1.1);
        filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(2.2) brightness(1.1);
    }

    50% {
        -webkit-filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(2.2) brightness(1.1);
        filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(2.2) brightness(1.1);
    }

    100% {
        -webkit-filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(1) brightness(1);
        filter: drop-shadow(0px 0px 2rem rgb(0, 213, 255)) saturate(1) brightness(1);
    }
}

@keyframes cardLift {
    0% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    50% {
        transform: translateY(-50px) scale(1.1);
        opacity: 0.8;
    }

    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes cardDrop {
    0% {
        transform: translateY(-50px) scale(1.1);
        opacity: 0.8;
    }

    100% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }
}

@keyframes quickBounceIn {
    0% {
        transform: translate3d(-50%, -50%, 0) scale(0.5);
        opacity: 0;
    }

    50% {
        transform: translate3d(-50%, -50%, 0) scale(1.5);
        opacity: 1;
    }

    100% {
        transform: translate3d(-50%, -50%, 0) scale(1);
        opacity: 1;
    }
}

.quick-bounce-in {
    animation: quickBounceIn 0.5s ease-out forwards;
}


.card-lift {
    animation: cardLift 0.5s ease-out forwards;
}

.card-drop {
    animation: cardDrop 0.5s ease-in forwards;
}