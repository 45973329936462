.wait-modal {
    --width: 175px;
    --height: 175px;
    --background: transparent;
    --border-radius: 50%;
    #ion-react-wrapper {
        // overflow: hidden;
        // border-radius:  50%;
    }
    .token-count {
        position: absolute;
        bottom: 0.5rem;
        left: 50%;
        width: 100%;
        z-index: 9;
        transform: translateX(-50%) translateY(20%);  
    }
}

.waiting-loader{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    background: linear-gradient(var(--gladiator-gold),var(--gladiator-gold-dark),var(--gladiator-gold));
    animation: animate 0.5s linear infinite;
    opacity: 0.75;
  }
  @keyframes animate{
    0%{
      transform: rotate(0deg);
    }
    100%{
      transform: rotate(360deg);
    }
  }
  .waiting-loader span{
    position:relative;
    weight:100%;
    height:100%;
    border-radius:50%;
    background: linear-gradient(var(--gladiator-gold),var(--gladiator-gold-dark),var(--gladiator-gold));
  }
  .waiting-loader span:nth-child(1)
  {
    filter: blur(5px);
  }
  .waiting-loader span:nth-child(2)
  {
    filter: blur(10px);
  }
  .waiting-loader span:nth-child(3)
  {
    filter: blur(25px);
  }
  .waiting-loader span:nth-child(4)
  {
    filter: blur(50px);
  }
  .waiting-loader:after{
    content:'';
    position: absolute;
    top:10px;
    left:10px;
    right:10px;
    bottom:10px;
    background:transparent;
    border-radius: 50%;
  }
  
.coin {
    position: absolute;
    top: 0.35rem;
    left: 0.5rem;
    width: 160px;
    height: 160px;
    z-index: 9;
}