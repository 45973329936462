.scene-background {

    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
    position: absolute;

    .dim-backdrop {
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        overflow: hidden;
        position: absolute;    
        transition: all 1.25s ease;
        background-color: rgba(0, 0, 0, 0.20);
        z-index: 2;
    }

    div.move {
        display: block;
        width: 100%;
        height: 100%;
        fill: #fff;
        position: absolute;
        top: 0%;
        left: 50%;
        overflow: hidden;
        z-index: 1;
        transform: translate(-50%, 0%) translateZ(0);
        backface-visibility: hidden;
        div.paths {
            // border: 10px solid red;
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            will-change: transform;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    
    div.base {
        display: block;
        width: 180%;
        height: 170%;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        z-index: 1;
        transform: translate(-50%, -50%) perspective(200px) translateZ(-140px) scale(1);
        backface-visibility: hidden;
        div.paths {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            will-change: transform;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
    
    div.mid {
        display: block;
        width: 115%;
        height: 115%;
        fill: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        overflow: hidden;
        transform: translate(-50%, -50%) perspective(200px) translateZ(20px) scale(1);
    }

    div.front {
        display: block;
        width: 105%;
        height: 105%;
        fill: #fff;
        position: absolute;
        top: 0%;
        left: 0%;
        overflow: hidden;
        z-index: 1;
        transform: translate(-2.5%, 0%) perspective(200px) translateZ(14px) scale(1);
        backface-visibility: hidden;
        div.paths {
            // border: 10px solid blue;
            transition: all 150ms ease;
            position: absolute;
            top: 0%;
            left: 0%;
            width: 101%;
            height: 101%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            will-change: transform;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }


}